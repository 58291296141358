import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
import ConfirmationScreen from "./ConfirmationScreen";

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [clientKey, setClientKey] = useState(process.env.REACT_APP_CLIENT_ID);


  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailError(!validateEmail(newEmail));
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/googlelogin`, {
        token:  credentialResponse.credential
      });
      setSuccess(true);
      setError("");      // handle successful login
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setEmail(err.response.data.email);
        navigate('/register', { state: { email: err.response.data.email } });
      } else {
        console.error(err);
      }
    }
  };

  const handleLogin = async () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/login`, { email, withCredentials: true, });
      setSuccess(true);
      setError("");      // handle successful login
    } catch (err) {
      if (err.response && err.response.status === 404) {
        navigate('/register', { state: { email } });
      } else {
        console.error(err);
      }
    }
  };

  return (
    <>
      {!success && (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" width="100%" maxWidth="1750px" alignItems="stretch" gap={6}>
            <Box flex={2} marginTop={4}>
              <Typography variant="h1" align="center" color="#050A30">
                Welcome to
                <br /> LinensNLove
              </Typography>
              <Box width="100%" sx={{ marginTop: 12, marginBottom: 5 }}>
                <Typography color="#050A30" paddingBottom={0.5}>
                  Email
                </Typography>
                <TextField
                  label="johndoe@gmail.com"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailError ? "Please enter a valid email" : ""}
                />
              </Box>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleLogin}
                sx={{ marginBottom: 2, textTransform: "none" }}
              >
                Join the community
              </Button>
              {/* Google Sign-In Button */}
              <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2, width:"100"}}>
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={() => {
                    console.log('Login Failed');
                    setError("Google login failed. Please try again.");
                  }}
                />
              </Box>
              {emailError && (
                <Typography color="red" align="center">
                  Please enter a valid email
                </Typography>
              )}
              {error && (
                <Typography color="red" align="center">
                  {error}
                </Typography>
              )}
              <Box
                sx={{
                  height: "1px",
                  backgroundColor: "#e0e0e0",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
            </Box>
            <Box
              flex={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              <img
                src="/images/login.png"
                alt="login"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        {success && <ConfirmationScreen />}
      </Box>
    </>
  );
};

export default Login;
