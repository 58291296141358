import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import ConfirmationScreen from "./ConfirmationScreen";
import { Loader } from "@googlemaps/js-api-loader";

const Field = ({ label, placeHolder, name, value, onChange, rows = 1, onBlur }) => {
  return (
    <Box sx={{ marginBottom: 1 }}>
      <Typography sx={{ marginBottom: 0.5 }}>{label}</Typography>
      <TextField
        placeholder={placeHolder}
        variant="outlined"
        fullWidth
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        rows={rows}
        multiline={rows > 1}
      />
    </Box>
  );
};

const RegisterForm = ({ role, email }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: email,
    role: role,
    schoolOrHotelName: "",
    address: "",
    zipCode: "",
    phoneNumber: "",
    details: "",
    latitude: null,
    longitude: null
  });
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      console.log("Google Maps API loaded successfully.");
    });
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const fetchAddressSuggestions = async (input) => {
    if (input.length > 3) {
      const service = new window.google.maps.places.AutocompleteService();
      const request = {
        input,
        componentRestrictions: { country: "us" },
        types: ["address"],
      };

      service.getPlacePredictions(request, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setAddressSuggestions(predictions.map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id,
          })));
        } else {
          setAddressSuggestions([]);
        }
      });
    } else {
      setAddressSuggestions([]);
    }
  };

  const fetchPlaceDetails = (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    const request = {
      placeId,
      fields: ['address_components', 'formatted_address', 'geometry'],
    };

    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const addressComponents = place.address_components;
        let zipCode = '';
        addressComponents.forEach((component) => {
          if (component.types.includes('postal_code')) {
            zipCode = component.long_name;
          }
        });

        setFormData((prevFormData) => ({
          ...prevFormData,
          address: place.formatted_address,
          zipCode: zipCode,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        }));
      }
    });
  };

  const handleAddressChange = (e) => {
    handleChange(e);
    fetchAddressSuggestions(e.target.value);
  };

  const handleAddressSelect = (description, placeId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: description,
    }));
    setAddressSuggestions([]);
    fetchPlaceDetails(placeId);
  };

  const validateForm = () => {
    const { name, schoolOrHotelName, address, zipCode, phoneNumber, details } = formData;
    if (!name || !schoolOrHotelName || !address || !zipCode || !phoneNumber || !details) {
      setError("Please fill out all fields.");
      return false;
    }
    if (!/^\d{5}(-\d{4})?$/.test(zipCode)) {
      setError("Please enter a valid zip code.");
      return false;
    }
    if (!/^\d{10}$/.test(phoneNumber)) {
      setError("Please enter a valid phone number.");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/register`, formData);
      setSuccess(true);
      setError("");
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ width: "300px" }}>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight="bold"
          textTransform="uppercase"
          sx={{ marginBottom: 5 }}
        >
          {role === "hotelManager" ? "Join as Hotel Manager" : "Join as Volunteer Member"}
        </Typography>
        {!success && (
          <>
            <Field
              label="Your name:"
              placeHolder="Full name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <Field
              label={role === "hotelManager" ? "Name of your Hotel" : "Name of your High School"}
              placeHolder={role === "hotelManager" ? "Hotel Name" : "High School Name"}
              name="schoolOrHotelName"
              value={formData.schoolOrHotelName}
              onChange={handleChange}
            />
            <Field
              label={role === "hotelManager" ? "Hotel Address" : "High School Address"}
              placeHolder="Start typing the address"
              name="address"
              value={formData.address}
              onChange={handleAddressChange}
            />
            {addressSuggestions.length > 0 && (
              <Box 
                sx={{ 
                  position: "absolute", 
                  zIndex: 1000, 
                  background: "white", 
                  width: "300px",  // Decreased width
                  border: "1px solid #ccc",
                  maxHeight: "200px",  // Added max height
                  overflowY: "auto",  // Added scroll for overflow
                }}
              >
                {addressSuggestions.map((suggestion, index) => (
                  <Box
                    key={index}
                    sx={{ 
                      padding: "8px", 
                      cursor: "pointer",
                      textAlign: "center",  // Center the text
                      '&:hover': {
                        backgroundColor: "#f0f0f0",  // Add hover effect
                      },
                    }}
                    onClick={() => handleAddressSelect(suggestion.description, suggestion.placeId)}
                  >
                    {suggestion.description}
                  </Box>
                ))}
              </Box>
            )}
            <Field
              label="Zip Code"
              placeHolder="Zip Code (auto-filled)"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
            />
            <Field
              label="Contact phone number"
              placeHolder="Your phone number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <Field
              label="Request details"
              placeHolder="Tell us more about yourself"
              name="details"
              value={formData.details}
              onChange={handleChange}
              rows={4}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ display: "block", mx: "auto", mt: 4, width: "100%" }}
            >
              Register
            </Button>
          </>
        )}
        {success && <ConfirmationScreen />}
      </Box>
    </Box>
  );
};

export default RegisterForm;
