import * as React from 'react';

import { Button, Dialog, DialogTitle, Box, DialogContent, Typography } from '@mui/material';

export default function PopUp(props) {

    const{title, children, openPopUp, handleClose} = props;

  return (
    <>
        <Dialog open={openPopUp} onClose={handleClose} PaperProps={{
            sx: {
              borderRadius: '16px',
              minWidth: '660px',
            },
        }}>
            <DialogTitle>
                <Typography sx={{fontSize: '24px', fontWeight: '700'}}>{title}</Typography>
            </DialogTitle>
            <DialogContent sx={{padding: '30px'}}>
                {children}
            </DialogContent>
        </Dialog>
    </>

  );
}




