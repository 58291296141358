import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import DeliverySection from "./DeliverySection";
import WithdrawConfirmationPopup from "./WithdrawConfirmationPopup";
import EditDeliveryPopup from "./EditDeliveryPopup";
import DeliveryCard from "./DeliveryCard";
import NewDeliveryPopup from "./NewDeliveryPopup";
import DeliveryButton from "./DeliveryButton";

const DeliveriesPage = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [showWithdrawConfirmation, setShowWithdrawConfirmation] =
    useState(false);
  const [currentDelivery, setCurrentDelivery] = useState(null);
  const [showEditDeliveryPopup, setShowEditDeliveryPopup] = useState(false);
  const [showNewDeliveryPopup, setShowNewDeliveryPopup] = useState(false);
  const [role, setRole] = useState("");

  const handleNewDelivery = (delivery) => {
    setDeliveries([...deliveries, delivery]);
    setShowNewDeliveryPopup(false);
  };

  const handleShowParticipateList = async (delivery) => {
    const deliveryId = delivery._id;
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/chapterpresident/delivery/list/${deliveryId}`, {
        withCredentials: true,
        responseType: 'blob', // This tells axios to treat the response as binary data
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      });

      // Check the content type of the response
      const contentType = response.headers['content-type'];
      if (contentType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        console.warn('Unexpected content type:', contentType);
        // You might want to handle this case, perhaps by showing a warning to the user
      }

      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // Create a link element, set the download attribute, and click it
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `participants_delivery_${deliveryId}.xlsx`;

      // Append to the document, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the file:', error);
      // Handle the error appropriately (e.g., show an error message to the user)
    }
  };

  const handleSignUp = async (deliveryId) => {
    try {
      const response = await axios.post(
        `http://localhost:3001/volunteer/${deliveryId}`,
          {},
          {
            withCredentials: true, 
          }
        );
      setDeliveries((prevDeliveries) =>
        prevDeliveries.map((delivery) =>
          delivery._id === response.data._id ? {...response.data, isJoined: true} : delivery
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleDiscard = async (deliveryId) => {
    try {
      const response = await axios.delete(
        `http://localhost:3001/volunteer/${deliveryId}`,
          {
            withCredentials: true, 
          }
        );
      setDeliveries((prevDeliveries) =>
        prevDeliveries.map((delivery) =>
          delivery._id === response.data._id ? response.data : delivery
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        // const response = await axios.get(
        //   "http://localhost:3001/chapterpresident/delivery",
        //   { withCredentials: true }
        // );
        const response = await axios.get(
          "http://localhost:3001/delivery/getDeliveries",
          { withCredentials: true }
        );
        setDeliveries(response.data.deliveries);
        setRole(response.data.userStatus);
      } catch (err) {
        console.error(err);
      }
    };
    fetchDeliveries();
  }, []);

  const handleWithdrawDelivery = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/chapterpresident/delivery/${id}`, {
            withCredentials: true,
        })
      setDeliveries(deliveries.filter((delivery) => delivery._id !== id));
      setShowWithdrawConfirmation(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmDelivery = async (deliveryId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/chapterpresident/delivery/confirm`,
        { deliveryId: deliveryId },
        { withCredentials: true }
      );
      // Update the local state to reflect this change
      setDeliveries((prevDeliveries) =>
        prevDeliveries.map((delivery) =>
          delivery._id === deliveryId
            ? { ...delivery, status: "upcomingDelivery" }
            : delivery
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditDelivery = async (updatedDelivery) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/chapterpresident/delivery`,
        {
          id: updatedDelivery._id,
          date: updatedDelivery.deliveryDate,
          time: updatedDelivery.deliveryTime,
          deliveryAddress: updatedDelivery.deliveryAddress,
        },
        { withCredentials: true }
      );
      setDeliveries((prevDeliveries) =>
        prevDeliveries.map((delivery) =>
          delivery._id === response.data._id ? response.data : delivery
        )
      );
      setShowEditDeliveryPopup(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnManagePhotos = async (deliveries, links) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/chapterpresident/add/photo/${deliveries._id}`, {
        googleLinks: links
      }, {
        withCredentials: true,
      });
      setDeliveries((prevDeliveries) =>
        prevDeliveries.map((delivery) =>
          delivery._id === response.data._id ? response.data : delivery
        )
      );

    } catch (err) {
      console.error(err);
    }
  }

  const notConfirmedDeliveries = deliveries
  .filter((d) => d.status === "notConfirmedDelivery")
  .sort((a, b) => (a.placeConfirmation === 'Hotel' ? -1 : 1));
  console.log(notConfirmedDeliveries);
  const upcomingDeliveries = deliveries.filter(
    (d) => d.status === "upcomingDelivery"
  );
  
  const pastDeliveries = deliveries.filter(
    (d) => d.status === "pastDelivery"
  );

  return (
      <Box marginTop="28px" marginLeft="16px">
        { role === "chapterPresident" && <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          {/* <Button
            sx={{ borderRadius: "12px" }}
            variant="contained"
            color="primary"
            onClick={() => setShowNewDeliveryPopup(true)}
          >
            New Delivery
          </Button> */}
          <DeliveryButton type='new' onSave={handleNewDelivery}/>
        </Box>}

        {deliveries.length === 0 ? (
          <Typography variant="h6" color="textSecondary">
            No deliveries available
          </Typography>
        ) : (
          <>
            {(role === "chapterPresident" || role === 'Admin') && notConfirmedDeliveries.length > 0 &&
              <DeliverySection
                title="Not confirmed deliveries"
                deliveries={notConfirmedDeliveries}
                onWithdraw={(delivery) => {
                  setCurrentDelivery(delivery);
                  setShowWithdrawConfirmation(true);
                }}
                onEdit={handleEditDelivery}
                onConfirm={handleConfirmDelivery}
                onSignUp={handleSignUp}
                onParticipateList={handleShowParticipateList}
                onHandlePhotos={handleOnManagePhotos}
                onDiscard={handleDiscard}
                role = {role}
              />
            }

            {upcomingDeliveries.length > 0 &&
              <DeliverySection
                title="Upcoming deliveries"
                deliveries={upcomingDeliveries}
                onEdit={handleEditDelivery}
                onParticipateList={handleShowParticipateList}
                onHandlePhotos={handleOnManagePhotos}
                onSignUp={handleSignUp}
                onDiscard={handleDiscard}

                onWithdraw={(delivery) => {
                  setCurrentDelivery(delivery);
                  setShowWithdrawConfirmation(true);

                  }
                }
                role = {role}
              />
            }
            {pastDeliveries.length > 0 &&
              <DeliverySection
                title="Past deliveries"
                deliveries={pastDeliveries}
                role = {role}
                onManagePhotos={handleOnManagePhotos}
                onEdit={handleEditDelivery}
                onParticipateList={handleShowParticipateList}
              />
            }
          </>
        )}
        {showNewDeliveryPopup && (
          <NewDeliveryPopup
            onClose={() => setShowNewDeliveryPopup(false)}
            onSave={handleNewDelivery}
          />
        )}
        {showWithdrawConfirmation && (
          <WithdrawConfirmationPopup
            open={showWithdrawConfirmation}
            onClose={() => setShowWithdrawConfirmation(false)}
            onConfirm={() => handleWithdrawDelivery(currentDelivery._id)}
          />
        )}
      </Box>
  );
};

export default DeliveriesPage;
