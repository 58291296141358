import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Snackbar,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { Loader } from "@googlemaps/js-api-loader";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  borderBottom: '1px solid #e0e0e0',
  padding: '16px 24px',
  fontWeight: 'bold',
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '24px',
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '16px 24px',
  borderTop: '1px solid #e0e0e0',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: '6px 16px',
  borderRadius: '4px',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: '16px',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '16px',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: '10px 14px',
  },
}));

const SuggestionsList = styled(List)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1000,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  maxHeight: '200px',
  overflowY: 'auto',
}));

const SuggestionItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const EditHotelPopUp = ({ open, onClose, onHotelSaved, hotel }) => {
    console.log("hotel: ", hotel);

  const [formData, setFormData] = useState({
    chapterName: '',
    email: '',
    hotelName: '',
    hotelAddress: '',
    hotelZipCode: '',
    phone: '',
    name: '',
    thumbnail: '',
  });
  const [chapters, setChapters] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const fileInputRef = useRef(null);

  const [suggestions, setSuggestions] = useState([]);
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      console.log("Google Maps API loaded successfully.");
    });
  }, []);

  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/chapter/getChapters`, { withCredentials: true });
        const data = response.data.result;
        setChapters(data.filter((d) => d.status === "activeChapter"));
      } catch (error) {
        console.error('Error fetching chapters:', error);
      }
    };

    fetchChapters();

  }, []);

  useEffect(() => {
    if (hotel) {
      setFormData({
        chapterName: hotel.chapter || '',
        email: hotel.email || '',
        hotelName: hotel.hotelName || '',
        hotelAddress: hotel.hotelAddress || '',
        hotelZipCode: hotel.hotelZipCode || '',
        phone: hotel.phoneNumber || '',
        name: hotel.hotelManager || '',
        thumbnail: hotel.picturePath || '',
      });
    }
  }, [hotel]);
    console.log("formData: ", formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    console.log('file: ', file);
    setFormData((prev) => ({ ...prev, thumbnail: file.name }));
  };

  const handleChapterChange = (e) => {
    const value = e.target.value;
    console.log("Selected chapter:", value);
    setFormData((prev) => {
      const updatedData = { ...prev, chapterName: value };
      console.log("Updated formData after chapter selection:", updatedData);
      return updatedData;
    });
  };

  const handleSubmit = async () => {
    try {
      console.log("formData: ", formData);

      const response = await axios.put(`/api/admin/hotels/${hotel.hotelId}`, formData);
      console.log('response data: ', response.data.hotel);
      console.log('formData: ', formData);

      setSnackbarMessage('Hotel updated successfully!');
      setSnackbarOpen(true);
      onHotelSaved(response.data.hotel);
      onClose();
    } catch (error) {
      console.error('Error editing hotel:', error);
      setSnackbarMessage('Error editing hotel. Please try again.');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchSuggestions = async (input) => {
    if (input.length > 3) {
      const service = new window.google.maps.places.AutocompleteService();
      const request = {
        input,
        componentRestrictions: { country: "us" },
        types: ["establishment"],
      };

      service.getPlacePredictions(request, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions.map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id,
          })));
        } else {
          setSuggestions([]);
        }
      });
    } else {
      setSuggestions([]);
    }
  };

  const fetchAddressSuggestions = async (input) => {
    if (input.length > 3) {
      const service = new window.google.maps.places.AutocompleteService();
      const request = {
        input,
        componentRestrictions: { country: "us" },
        types: ["geocode"],
      };

      service.getPlacePredictions(request, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setAddressSuggestions(predictions.map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id,
          })));
        } else {
          setAddressSuggestions([]);
        }
      });
    } else {
      setAddressSuggestions([]);
    }
  };

  const fetchPlaceDetails = (placeId, fieldName) => {
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    const request = {
      placeId,
      fields: ['formatted_address'],
    };

    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: place.formatted_address,
        }));
      }
    });
  };

  const handleHotelNameChange = (e) => {
    handleChange(e);
    fetchSuggestions(e.target.value);
  };

  const handleHotelNameSelect = (description, placeId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hotelName: description,
    }));
    setSuggestions([]);
    fetchPlaceDetails(placeId, "hotelAddress");
  };

  const handleAddressChange = (e) => {
    handleChange(e);
    fetchAddressSuggestions(e.target.value);
  };

  const handleAddressSelect = (description, placeId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hotelAddress: description,
    }));
    setAddressSuggestions([]);
    fetchPlaceDetails(placeId, "address");
  };

  return (
    <>
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle>Edit Hotel Details</StyledDialogTitle>
      <StyledDialogContent style={{ paddingTop: "1rem"}}/*For some reason there is a bug with DialogTitle that overrides the padding. This is a fix */ >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <StyledFormControl fullWidth>
            <InputLabel id="chapter-label">Chapter</InputLabel>
            <StyledSelect
              labelId="chapter-label"
              id="chapterName"
              name="chapterName"
              value={formData.chapterName}
              onChange={handleChange}
              required
            >
              {chapters.map((chapter) => (
                <MenuItem key={chapter.chapterId} value={chapter.chapterName}>
                  {chapter.chapterName}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
          <StyledTextField
            name="email"
            label="Email"
            value={formData.email}
            onChange={handleChange}
            required
            fullWidth
          />
          <Box sx={{ position: 'relative' }}>
          <StyledTextField
            name="hotelName"
            label="Hotel name"
            value={formData.hotelName}
            onChange={handleHotelNameChange}
            required
            fullWidth
            InputProps={{
              endAdornment: isSearching ? <CircularProgress size={20} /> : null,
            }}
          />
          {suggestions.length > 0 && (
            <SuggestionsList>
              {suggestions.map((suggestion, index) => (
                <SuggestionItem
                  key={index}
                  onClick={() => handleHotelNameSelect(suggestion.description, suggestion.placeId)}
                >
                  <ListItemText primary={suggestion.description} />
                </SuggestionItem>
              ))}
            </SuggestionsList>
          )}
          </Box>
          <StyledTextField
            name="hotelAddress"
            label="Address"
            value={formData.hotelAddress}
            onChange={handleAddressChange}
            required
            fullWidth
          />
          {addressSuggestions.length > 0 && (
            <SuggestionsList>
              {addressSuggestions.map((suggestion, index) => (
                <SuggestionItem
                  key={index}
                  onClick={() => handleAddressSelect(suggestion.description, suggestion.placeId)}
                >
                  <ListItemText primary={suggestion.description} />
                </SuggestionItem>
              ))}
            </SuggestionsList>
          )}
          <StyledTextField
            name="phone"
            label="Phone (optional)"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
          />
          <StyledTextField
            name="name"
            label="Name (optional)"
            value={formData.name}
            onChange={handleChange}
            fullWidth
          />
          <Box
            sx={{
              border: '2px dashed #ccc',
              borderRadius: '4px',
              padding: '16px',
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => fileInputRef.current.click()}
          >
            <Typography variant="body2" color="textSecondary">
              Drag and Drop
            </Typography>
            <Typography variant="body2" color="textSecondary">
              or
            </Typography>
            <Typography variant="body2" color="primary">
              Browse
            </Typography>
            <input
              type="file"
              ref={fileInputRef}
              // style={{ display: 'none' }}
              onChange={handleThumbnailChange}
              accept="image/*"
            />
          </Box>
        </Box>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton onClick={onClose} variant="outlined">Cancel</StyledButton>
        <StyledButton onClick={handleSubmit} variant="contained" color="primary">
          Save
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default EditHotelPopUp;
