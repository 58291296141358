import React from "react";
import { Box, Typography, List, ListItem } from "@mui/material";
import DonationCard from "./DonationCard";

const DonationSection = ({ title, donations, onEdit, onWithdraw, onConfirm }) => {
  return (
    <Box marginBottom={4}>
      <Typography variant="h5" gutterBottom color="#253f4b">
        {title}
      </Typography>
      {donations.length === 0 ? (
        <Typography color="textSecondary">No donations available</Typography>
      ) : (
        <List >
          {donations.map((donation, index) => (
            <ListItem key={index} disablePadding sx={{ mb: 2 }}>
              <DonationCard
                donation ={donation}
                past={donation.status === 'pastDelivery'}
                onEdit={(updatedDonation) => onEdit(updatedDonation)}
                onWithdraw={() => onWithdraw(donation)}
                onConfirm={() => onConfirm(donation._id)}
                type = {donation.placeConfirmation}
              />
            </ListItem>

          ))}
        </List>
      )}
    </Box>
  );
};

export default DonationSection;
