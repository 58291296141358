import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StatusScreen from "./StatusScreen";

const StatusPage = () => {
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/status/check`, {
          withCredentials: true,
        });
        console.log(response.data);
        setStatus(response.data.status);
        if ((response.data.status === "approvedMember" && response.data.role === "volunteerMember") || (response.data.role === "chapterPresident")) {
          navigate("/delivery");
        }
        else if (response.data.status === "approvedMember" && response.data.role === "hotelManager"){
          navigate("/donations");
        }
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    fetchStatus();
  }, [navigate]);

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <StatusScreen status={status} />
    </div>
  );
};

export default StatusPage;
