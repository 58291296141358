import { Box, CssBaseline } from "@mui/material";
import NavBar from "components/NavBar";
import { useEffect, useState } from "react";
import axios from "axios";
import SideBar from "components/SideBar";

const Layout = ({ children }) => {

	const [role, setRole] = useState("");

	useEffect(() => {
		const fetchData = async () => {
		  try {
			const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/auth/role`, {
				withCredentials: true
			});
			setRole(response.data);
		  } catch (error) {
			 console.error('Error fetching data:', error);
		  }
  
		};
		fetchData();    
  
	}, []);
  
  return (
    <Box>
      <CssBaseline />
			<NavBar role={role}/>
			<SideBar role={role}/>
      <Box
        marginLeft="300px"
		marginTop='90px'
        marginRight={3}
        padding={3}
        sx={{ backgroundColor: "#f5f7fa" }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
