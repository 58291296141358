import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Snackbar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ProfileForm = () => {
  const [formData, setFormData] = useState({
    userId: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    highSchoolName: '',
    highSchoolAddress: '',
    picturePath: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const userRole = useSelector((state) => state.user);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`http://localhost:3001/profile`, {
        withCredentials: true,
      });
      const userData = { ...response.data };
      setFormData(userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setOpenConfirmDialog(true);
    }
  };

  const handleConfirmUpload = async () => {
    setOpenConfirmDialog(false);
    if (selectedFile) {
      setIsUploading(true);
      try {
        const formData = new FormData();
        formData.append('profilePicture', selectedFile);

        const response = await axios.post('http://localhost:3001/profile/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        });

        setFormData(prevData => ({
          ...prevData,
          picturePath: response.data.imageUrl
        }));
      } catch (error) {
        console.error('Error uploading file:', error);
        // You might want to show an error message to the user here
      } finally {
        setIsUploading(false);
        setSelectedFile(null);
      }
    }
  };

  const handleCancelUpload = () => {
    setOpenConfirmDialog(false);
    setSelectedFile(null);
  };

  const fileInputRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.patch(`http://localhost:3001/profile/${userRole}`, formData, { withCredentials: true });
      setOpenSnackbar(true);
    } catch (error) {
      console.log("Error updating profile: ", error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{
      width: '100%',
      maxWidth: '1000px',
      margin: '0 auto',
      padding: '24px',
      backgroundColor: '#F8F9FD',
    }}>
      <Typography
        variant="h6"
        sx={{
          marginBottom: '16px',
          fontWeight: 500,
          color: "#343C6A",
        }}
      >
        Edit profile
      </Typography>
      <Box sx={{
        backgroundColor: 'white',
        padding: '32px',
        borderRadius: '12px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.05)'
      }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3} display="flex" flexDirection="column" alignItems="center">
              <Box position="relative" mb={2}>
                <Avatar
                  src={formData.picturePath || "/path-to-default-image.jpg"}
                  sx={{ width: 120, height: 120 }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    backgroundColor: '#1814F3',
                    borderRadius: '50%',
                    padding: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => fileInputRef.current.click()}
                >
                  <EditIcon sx={{ color: 'white', fontSize: 20 }} />
                </Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  style={{ display: 'none' }}
                  accept="image/*"
                />
              </Box>
              {isUploading && <Typography>Uploading...</Typography>}
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>Full Name</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>Email</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>Phone Number</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>High School Name</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="highSchoolName"
                    value={formData.highSchoolName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ mb: 1 }}>High School Address</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="highSchoolAddress"
                    value={formData.highSchoolAddress}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end" mt={2}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#1814F3',
                  '&:hover': {
                    backgroundColor: '#0e0c9e',
                  },
                  px: 4,
                  py: 1,
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Your changes have been saved"
      />
      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelUpload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Upload"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to upload this image as your new profile picture?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpload}>Cancel</Button>
          <Button onClick={handleConfirmUpload} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfileForm;