import { Box, Button } from "@mui/material";

const RoleSelect = ({ handleRoleSelect }) => {
  const roles = [
    { volunteerMember: "I want to join as a volunteer" },
    { hotelManager: "I want to donate as a hotel" },
    // { shelter: "I require linens for a shelter" },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={10}
      maxWidth={200}
    >
      {roles.map((role) => (
        <Button
          size="large"
          key={Object.keys(role)[0]}
          variant="outlined"
          color="primary"
          onClick={() => handleRoleSelect(Object.keys(role)[0])}
          sx={{ textTransform: "none", fontSize: 16 }}
        >
          {Object.values(role)}
        </Button>
      ))}
    </Box>
  );
};

export default RoleSelect;
