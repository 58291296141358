import * as React from 'react';

import { Button, Dialog, DialogTitle, Box, DialogContent, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ReactComponent as HotelIcon2} from '../../images/hotel.svg';

export default function HotelTable({hotels, title, handleClick, anchorEl, open, handleClose, menuItems}) {

    const headers = ["Hotel", "Email", "Phone", "Name", "Address", "Chapter"];


    return (
        <>
        <Box sx={{marginTop: '3%', paddingLeft: '5%', fontWeight: 500, fontSize: 18, color: "#343C6A"}}>
            {title}
        </Box>
        <TableContainer component={Paper} sx={{ width: '90%', margin: 'auto', marginTop: '2rem', borderRadius: '25px' }}>
            <Table sx={{ minWidth: { xs: '100%', sm: 400 }, maxWidth: '100%'}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: { xs: '10%', sm: '5%' }, padding: 0, borderBottom: 'none' }}></TableCell>
                        {headers.map((header, index) =>(
                        <TableCell key= {index} sx={{ width: 178, height: 49, fontWeight: 'bold', borderBottom: '1px solid black' }}>
                            <Typography variant="body" component="h1" color="black"
                                sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    lineHeight: '21.78px',
                                    textAlign: 'center'
                                }}>
                                {header}
                                </Typography>
                        </TableCell>
                        ))}
                        <TableCell align="center" sx={{ width: 10, borderBottom: '1px solid black' }}> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hotels.map((row) => (
                        <TableRow key={row.hotelId} sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: 70 }}>
                            <TableCell sx={{ width: 51, verticalAlign: 'middle', paddingRight: 1, borderBottom: 'none' }}>
                            {row.picturePath ? (
                                <img src={row.picturePath} style={{ width: 35, height: 35 }} />
                            ) : (
                                <HotelIcon2 style={{ width: 35, height: 35 }} />
                            )}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ width: "178px", height: 50, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                <Typography variant="body" component="h2" color="black"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '21.78px',
                                        WebkitLineClamp: 3,
                                        maxHeight: 20,
                                        textAlign: 'center',
                                    }}>
                                    {row.hotelName}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ width: "178px", height: 50, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <Typography variant="body" component="h1" color="black"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '16.94px',
                                        WebkitLineClamp: 3,
                                        maxHeight: 20,
                                        textAlign: 'center'
                                    }}>
                                    {row.email}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ width: "178px", height: 50, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <Typography variant="body" component="h1" color="black"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '16.94px',
                                        WebkitLineClamp: 3,
                                        maxHeight: 20,
                                        textAlign: 'center'
                                    }}>
                                    {row.phoneNumber}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ width: "178px", height: 50, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <Typography variant="body" component="h1" color="black"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '16.94px',
                                        WebkitLineClamp: 3,
                                        maxHeight: 20,
                                        textAlign: 'center'
                                    }}>
                                    {row.hotelManager}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ width: "178px", height: 50, overflow: 'hidden' }}>
                                <Typography variant="body" component="h1" color="black"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '16.94px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        textAlign: 'center'
                                    }}>
                                    {row.hotelAddress}, {row.hotelZipCode}
                                </Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ width: "178px", overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <Typography variant="body" component="h1" color="black"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '16.94px',
                                        WebkitLineClamp: 2,
                                        maxHeight: 20,
                                        textAlign: 'center',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                    {row.chapter}
                                </Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ width: 10 }}>
                                <IconButton onClick={(event) => handleClick(event, row)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            {menuItems.map((item, index) => (
                <MenuItem key={index} onClick={item.onClick}>{item.label}</MenuItem>
            ))}
        </Menu>
    </>

  );
}




