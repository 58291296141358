import React from "react";
import { Box, Typography, List, ListItem } from "@mui/material";
import DeliveryCard from "./DeliveryCard";


const DeliverySection = ({ title, deliveries, onEdit, onWithdraw, onConfirm, onSignUp, onParticipateList, role, onManagePhotos, onDiscard}) => {

  return (
    <Box marginBottom={4}>
      <Typography variant="h5" gutterBottom color="#253f4b">
        {title}
      </Typography>
      {deliveries.length === 0 ? (
        <Typography color="textSecondary">No deliveries available</Typography>
      ) : (
        <List disablePadding>
          {deliveries.map((delivery, index) => (
            <ListItem key={index} disablePadding sx={{ mb: 2 }}>
              <DeliveryCard
                delivery={delivery}
                onEdit={onEdit}
                onWithdraw={onWithdraw}
                onConfirm={onConfirm}
                onSignUp={onSignUp}
                onParticipateList={onParticipateList}
                role={role}
                onManagePhotos={onManagePhotos}
                onDiscard={onDiscard}

              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default DeliverySection;
