import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Card, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { format } from 'date-fns';
import { useState } from "react";
import EditDonationPopup from "./EditDonationPopup";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import CheckIcon from '@mui/icons-material/Check';

const DonationCard = ({donation, past = false, onEdit, onWithdraw, onConfirm, type}) => {
  const bgColor = past ? "#ededed" : "white";
  const textColor = past ? 'gray' : 'black';
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [showEditDonationPopup, setShowEditDonationPopup] = useState(false);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  function convertTo12Hour(time24) {
    const [hours, minutes] = time24.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  }

  const handleEdit = () => {
    handleMenuClose();
    setShowEditDonationPopup(true);
  };

  const handleWithdraw = () => {
    handleMenuClose();
    onWithdraw();
  };

  const handleSaveEdit = (updatedDonation) => {
    onEdit(updatedDonation);
    setShowEditDonationPopup(false);
  };

  const handleConfirm = () => {
    handleMenuClose();
    onConfirm();
  };

  return (
    <>
      <Card
        sx={{
          backgroundColor: bgColor,
          padding: 3,
          paddingLeft: 5,
          display: "flex",
          justifyContent: "left",
          gap: 6,
          alignItems: "top",
          width: "100%",
          borderRadius: "16px",
          boxShadow: 0,
          borderStyle: type === 'Chapter' && donation.status === 'notConfirmedDelivery' ? 'solid' : 'none',
          borderColor: 'red'
        }}
      >
        <Box>
          <Typography
            variant="body1"
            fontWeight="semibold"
            fontSize={24}
            color={textColor}
          >
            {format(new Date(donation.deliveryDate), 'MM/dd/yyyy')}
          </Typography>
          <Typography
            variant="body2"
            fontWeight="semibold"
            fontSize={24}
            color= {textColor}
          >
            {convertTo12Hour(donation.deliveryTime)}
          </Typography>
        </Box>
        <Box textAlign="left" flexGrow={1}>
          <Typography
            variant="body1"
            fontWeight="bold"
            fontSize={14}
            paddingTop={0.4}
            color={textColor}
          >
            {`Meeting Point: ${donation.meetingAddress}`}
          </Typography>
          <Typography variant="body2" fontSize={14} color={textColor}>
          {donation.deliveryAddress}
          </Typography>
        </Box>
        {donation.status === 'notConfirmedDelivery' && (
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <IconButton onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <IconButton disableFocusRipple disableRipple sx={{cursor:'default'}}>
              <HistoryToggleOffIcon sx={{ fontSize: 30 }} />	
            </IconButton>
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              width: 200,
            },
          }}
        >
          {type === "Chapter" && <MenuItem onClick={handleConfirm}>Confirm</MenuItem>}
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          {type === 'Hotel' && <MenuItem onClick={handleWithdraw}>Withdraw</MenuItem>}
          
        </Menu>
      </Card>
      {showEditDonationPopup && (
        <EditDonationPopup
          open={showEditDonationPopup}
          onClose={() => setShowEditDonationPopup(false)}
          donation={donation}
          onSave={handleSaveEdit}
        />
      )}
    </>
  );
};

export default DonationCard;
