import React from "react";
import { Box, Typography } from "@mui/material";

const ConfirmationScreen = () => {
  return (
    <Box sx={{ textAlign: "center", marginTop: 4 }}>
      <Typography variant="h5">Email Confirmation</Typography>
      <Typography>
        We have sent an email to confirm the validity of your email address.
        Follow the link provided to complete the registration.
      </Typography>
    </Box>
  );
};

export default ConfirmationScreen;
