// index.js
import React, { useState } from "react";
import RoleSelect from "./RoleSelect";
import RegisterForm from "./RegisterForm";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const RegisterPage = () => {
  const [role, setRole] = useState("");
  const location = useLocation();
  const emailFromState = location.state?.email || "";

  const handleRoleSelect = (selectedRole) => {
    setRole(selectedRole);
  };

  return (
    <Box
      top={-64}
      position="relative"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {role === "" && <RoleSelect handleRoleSelect={handleRoleSelect} />}
      {(role === "volunteerMember" || role === "hotelManager") && (
        <RegisterForm role={role} email={emailFromState}/>
      )}
    </Box>
  );
};

export default RegisterPage;
