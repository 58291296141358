import * as React from 'react';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

import {ReactComponent as UserIcon} from '../../images/user2.svg';


export default function BasicTable() {
    const [anchorElRequested, setAnchorElRequested] = React.useState(null);
    const [selectedRequestedRow, setSelectedRequestedRow] = React.useState(null);
    const [anchorElApproved, setAnchorElApproved] = React.useState(null);
    const [selectedApprovedRow, setSelectedApprovedRow] = React.useState(null);

    const [anchorElInvitations, setAnchorElInvitations] = React.useState(null);
    const [anchorElRequests, setAnchorElRequests] = React.useState(null);

    const [selectedInvitationRow, setSelectedInvitationRow] = React.useState(null);
    const [selectedRequestRow, setSelectedRequestRow] = React.useState(null);

    const [volunteers, setVolunteers] = React.useState([]);

    const [selectedChapter, setSelectedChapter] = React.useState([]);

    const [anchorElAppoint, setAnchorElAppoint] = React.useState(null);
    const [chapters, setChapters] = React.useState([]);
    const [chaptersForAppoint, setChaptersForAppoint] = React.useState([]);


    const handleClickInvitations = (event, row) => {
      setAnchorElInvitations(event.currentTarget);
      setSelectedInvitationRow(row);
    };

    const handleCloseInvitations = () => {
      setAnchorElInvitations(null);
      setSelectedInvitationRow(null);
    };

    const handleClickRequests = (event, row) => {
      setAnchorElRequests(event.currentTarget);
      setSelectedRequestRow(row);
    };

    const handleCloseRequests = () => {
      setAnchorElRequests(null);
      setSelectedRequestRow(null);
    };

    const handleAppointHover = (event, row) => {
      setAnchorElAppoint(event.currentTarget);
      setChaptersForAppoint(row.chapters);
      setSelectedApprovedRow(row); // Ensure the correct row is set
    };

    const handleCloseAppoint = () => {
      setAnchorElAppoint(null);
      // setChaptersForAppoint([]);
    };
    const handleResend = async (row) => {
      console.log(row);
    };


    // const handleAppointPresident = async (chapterId) => {
    //   try {
    //     await axios.patch(`${process.env.REACT_APP_SERVER_URL}/admin/members/${selectedApprovedRow.memberId}/${chapterId}/appointAsPresident`);

    //     setApprovedMembers(approvedMembers.map(member => {
    //       if(member._id === selectedApprovedRow.memberId) {
    //         const chapterIndex = member.chapters.findIndex(c => c._id === chapterId);
    //         member.chapters[chapterIndex].status = 'president';
    //       }
    //       return member;
    //     }));
    //   } catch (error) {
    //     console.error('Error appointing president', error);
    //   }
    //   handleCloseAppoint();
    //   handleCloseApproved();
    // };



    useEffect(() => {
      const fetchData = async () => {
        try {
          const volunteers = await axios.get(`${process.env.REACT_APP_SERVER_URL}/chapterpresident/volunteer`, { withCredentials: true });
          console.log(volunteers.data);

          setVolunteers(volunteers.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, []);

    useEffect(() => {
      console.log('chaptersForAppoint updated', chaptersForAppoint)
    }, [chaptersForAppoint]);

    const Navigate = useNavigate();

    const handleButtonClick = (route) => {
      Navigate(route);
    };

    const handleClickRequested = (event, row) => {
      setAnchorElRequested(event.currentTarget);
      setSelectedApprovedRow(row);
      console.log(selectedApprovedRow)
    };

    const handleCloseRequested = () => {
      setAnchorElRequested(null);
      setSelectedRequestedRow(null);
    };

    const handleClickApproved = (event, row) => {
      console.log(row)
      setAnchorElApproved(event.currentTarget);
      setSelectedApprovedRow(row);
      setChaptersForAppoint([]); // Reset chapters for appoint

    };

    const handleCloseApproved = () => {
      setAnchorElApproved(null);
      setSelectedApprovedRow(null);
    };


    const handleApprove = async () => {
      try {
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}/chapterpresident/volunteer/${selectedApprovedRow._id}/${selectedApprovedRow.chapter._id}`, { withCredentials: true });
        console.log('Approve', selectedRequestedRow);
      } catch (error) {
        console.error('Error approving request:', error);
      }
      handleCloseRequested();
    };
  const handleRevoke = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/chapterpresident/volunteer/${selectedApprovedRow._id}/${selectedApprovedRow.chapter._id}`, { withCredentials: true });
      console.log('Revoke', selectedApprovedRow);
    } catch (error) {
      console.error('Error revoking request:', error);
    }
    handleCloseApproved();
  };

    const handleDecline = async () => {
      try {

        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/chapterpresident/volunteer/${selectedApprovedRow._id}/${selectedApprovedRow.chapter._id}`, { withCredentials: true });
        console.log('Decline', selectedRequestedRow);
      } catch (error) {
        console.error('Error declining request:', error);
      }
      handleCloseRequested();
    };

    // Get chapter method
    const getChapter = (member) => {
      //if(!member.chapters) return null;
      //console.log('member\'s chapter ', member.chapters);
      return member.chapters.find(c => c.status === 'member');
    };

    const handleEdit = () => {
      console.log('Edit', selectedApprovedRow);
      handleCloseApproved();
    };

    // const handlePresident = async () => {

    //   try {
    //     await axios.patch(`${process.env.REACT_APP_SERVER_URL}/admin/members/${selectedApprovedRow._id}/${selectedChapter._id}/appointAsPresident`)

    //     setApprovedMembers(approvedMembers.map(member => {
    //           if(member._id === selectedApprovedRow._id) {
    //               const chapterIndex = member.chapters.findIndex(
    //                 c => c._id === selectedChapter._id
    //               )
    //           member.chapters[chapterIndex].status = 'president'
    //         }
    //         return member;
    //       })
    //     )
    //   } catch (error) {
    //     console.error('Error appointing president', error)
    //   }
    //   handleCloseApproved()
    // }

  const openRequested = Boolean(anchorElRequested);
  const openApproved = Boolean(anchorElApproved);

  return (
    <Box>
      <Grid container>
        {/* content */}
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Box
              sx={{
                height: 'calc(100vh - 64px)',
                overflowY: 'auto',
                paddingTop: 2,
              }}
            >
            {volunteers.filter((row) => row.status === "invited").length > 0 && (
              <>
              <Box sx={{marginTop: '1%', paddingLeft: '5%', fontWeight: 500, fontSize: 18, color: "#343C6A"}}>
                Invitations
              </Box>

              <TableContainer component={Paper} sx={{ width: '90%', margin: 'auto', marginTop: '2rem', borderRadius: '25px' }}>
                <Table sx={{ minWidth: { xs: '100%', sm: 400 }, maxWidth: '100%'}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell sx={{ width: { xs: '10%', sm: '5%' }, padding: 0, borderBottom: 'none' }}></TableCell>
                    <TableCell sx={{ width: { xs: '30%', sm: '20%' }, height: '49px', fontWeight: 'bold', borderBottom: '1px solid black' }}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                              Name
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 178, height: 49, top: 185, left: 558, fontWeight: 'bold' , borderBottom: '1px solid black'}}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                              Email
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 178, height: 49, top: 185, left: 756, fontWeight: 'bold', borderBottom: '1px solid black' }}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                            Phone
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 178, height: 49, top: 185, left: 954, fontWeight: 'bold' , borderBottom: '1px solid black'}}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                            High School
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 178, height: 49, top: 185, left: 1152, fontWeight: 'bold' , borderBottom: '1px solid black'}}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                            Chapters
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ width: 10, borderBottom: '1px solid black'}}> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {volunteers.filter((row) => row.status === "invited").map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } , height: 70}}
                      >
                        <TableCell sx={{ width: 51, verticalAlign: 'middle', paddingRight: 1, borderBottom: 'none'  }}>
                        {row.picturePath !== "./images/default-profile.png" ? <img src={row.picturePath} style={{ width: 35, height: 35 }}  alt="profile" /> : <UserIcon style={{ width: 35, height: 35 }} />}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ width: "178px", height: 50, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                          <Typography variant="body" component="h2" color="black"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '21.78px',
                                WebkitLineClamp: 3,
                                maxHeight: 20,
                                textAlign: 'center'
                              }}>
                            {row.fullName}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{  width: "178px" , height: 50, overflow: 'hidden', textOverflow: 'ellipsis', }}>
                          <Typography variant="body" component="h1" color="black"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '16.94px',
                                WebkitLineClamp: 3,
                                maxHeight: 20,
                                textAlign: 'center'
                              }}>
                            {row.email}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{  width: "178px" , height: 50, overflow: 'hidden', textOverflow: 'ellipsis',}}>
                          <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  lineHeight: '16.94px',
                                  WebkitLineClamp: 3,
                                  maxHeight: 20,
                                  textAlign: 'center'
                                }}>
                              {row.phoneNumber}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: "178px" , height: 50, overflow: 'hidden', }}>
                          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Typography variant="body" component="h1" color="black"
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '16.94px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center',
                                                            }}>
                                {row.highSchoolName}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell align="left" sx={{  width: "178px" , height: 50, overflow: 'hidden', }}>
                          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Typography variant="body" component="h1" color="black"
                                    sx={{
                                      fontFamily: 'Inter',
                                      fontSize: '14px',
                                      fontWeight: 400,
                                      lineHeight: '16.94px',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: 3,
                                      WebkitBoxOrient: 'vertical',
                                      textAlign: 'center',
                                                              }}>
                                  {row.chapter.name}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell align="center" sx={{ width: 10 }}>
                          <IconButton onClick={(event) => handleClickInvitations(event, row)}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorElInvitations}
                            open={Boolean(anchorElInvitations)}
                            onClose={handleCloseInvitations}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          >
                            <MenuItem onClick={handleRevoke}>Revoke invite</MenuItem>
                            <MenuItem onClick={handleResend}>Resend</MenuItem>
                            <MenuItem onClick={handleEdit}>Edit</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </>
            )}
            { volunteers.filter((row) => row.status === "pending").length > 0 && (
              <>
              <Box sx={{marginTop: '1%', paddingLeft: '5%', fontWeight: 500, fontSize: 18, color: "#343C6A"}}>
                New member requests
              </Box>

              <TableContainer component={Paper} sx={{ width: '90%', margin: 'auto', marginTop: '2rem', borderRadius: '25px' }}>
                <Table sx={{ minWidth: { xs: '100%', sm: 400 }, maxWidth: '100%'}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell sx={{ width: { xs: '10%', sm: '5%' }, padding: 0, borderBottom: 'none' }}></TableCell>
                    <TableCell sx={{ width: { xs: '30%', sm: '20%' }, height: '49px', fontWeight: 'bold', borderBottom: '1px solid black' }}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                              Name
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 178, height: 49, top: 185, left: 558, fontWeight: 'bold' , borderBottom: '1px solid black'}}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                              Email
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 178, height: 49, top: 185, left: 756, fontWeight: 'bold', borderBottom: '1px solid black' }}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                            Phone
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 178, height: 49, top: 185, left: 954, fontWeight: 'bold' , borderBottom: '1px solid black'}}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                            High School
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 178, height: 49, top: 185, left: 1152, fontWeight: 'bold' , borderBottom: '1px solid black'}}>
                        <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '21.78px',
                                  textAlign: 'center'
                                }}>
                            Request
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ width: 10, borderBottom: '1px solid black'}}> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {volunteers.filter((row) => row.status === "pending").map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } , height: 70}}
                      >
                        <TableCell sx={{ width: 51, verticalAlign: 'middle', paddingRight: 1, borderBottom: 'none'  }}>
                        {row.picturePath !== "./images/default-profile.png" ? <img src={row.picturePath} style={{ width: 35, height: 35 }}  alt="profile" /> : <UserIcon style={{ width: 35, height: 35 }} />}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ width: "178px", height: 50, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                          <Typography variant="body" component="h2" color="black"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '21.78px',
                                WebkitLineClamp: 3,
                                maxHeight: 20,
                                textAlign: 'center'
                              }}>
                            {row.fullName}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{  width: "178px" , height: 50, overflow: 'hidden', textOverflow: 'ellipsis', }}>
                          <Typography variant="body" component="h1" color="black"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '16.94px',
                                WebkitLineClamp: 3,
                                maxHeight: 20,
                                textAlign: 'center'
                              }}>
                            {row.email}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{  width: "178px" , height: 50, overflow: 'hidden', textOverflow: 'ellipsis',}}>
                          <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  lineHeight: '16.94px',
                                  WebkitLineClamp: 3,
                                  maxHeight: 20,
                                  textAlign: 'center'
                                }}>
                              {row.phoneNumber}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: "178px" , height: 50, overflow: 'hidden', }}>
                          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Typography variant="body" component="h1" color="black"
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '16.94px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center',
                                                            }}>
                                {row.highSchoolName}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell align="left" sx={{  width: "178px" , height: 50, overflow: 'hidden', }}>
                          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Typography variant="body" component="h1" color="black"
                                    sx={{
                                      fontFamily: 'Inter',
                                      fontSize: '14px',
                                      fontWeight: 400,
                                      lineHeight: '16.94px',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: 3,
                                      WebkitBoxOrient: 'vertical',
                                      textAlign: 'center',
                                                              }}>
                                  {row.requestDetails}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell align="center" sx={{ width: 10 }}>
                          <IconButton onClick={(event) => handleClickRequested(event, row)}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorElRequested}
                            open={openRequested}
                            onClose={handleCloseRequested}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          >
                            <MenuItem onClick={handleApprove}>Approve</MenuItem>
                            <MenuItem onClick={handleDecline}>Decline</MenuItem>
                            <MenuItem onClick={handleEdit}>Edit</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </>
            )}
            {volunteers.filter((row) => row.status === "approved").length > 0 && (
              <>
                <Box sx={{ marginTop: '1%', paddingLeft: '5%', fontWeight: 500, fontSize: 18, color: "#343C6A" }}>
                  Approved members
                </Box>

                <TableContainer component={Paper} sx={{ width: '90%', margin: 'auto', marginTop: '2rem', borderRadius: '25px' }}>
                  <Table sx={{ minWidth: 400 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 51, padding: 0, borderBottom: 'none' }}></TableCell>
                        <TableCell sx={{ width: 178, height: 49, fontWeight: 'bold', borderBottom: '1px solid black' }}>
                          <Typography variant="body" component="h1" color="black" sx={{
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '21.78px',
                            textAlign: 'center'
                          }}>
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: 178, height: 49, fontWeight: 'bold', borderBottom: '1px solid black' }}>
                          <Typography variant="body" component="h1" color="black" sx={{
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '21.78px',
                            textAlign: 'center'
                          }}>
                            Email
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: 178, height: 49, fontWeight: 'bold', borderBottom: '1px solid black' }}>
                          <Typography variant="body" component="h1" color="black" sx={{
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '21.78px',
                            textAlign: 'center'
                          }}>
                            Phone
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: 178, height: 49, fontWeight: 'bold', borderBottom: '1px solid black' }}>
                          <Typography variant="body" component="h1" color="black" sx={{
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '21.78px',
                          }}>
                            High School
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: 178, height: 49, fontWeight: 'bold', borderBottom: '1px solid black' }}>
                          <Typography variant="body" component="h1" color="black" sx={{
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '21.78px',
                            textAlign: 'center'
                          }}>
                            Chapters
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ width: 10, borderBottom: '1px solid black'}}> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {volunteers.filter((row) => row.status === "member").map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } , height: 70}}
                      >
                        <TableCell sx={{ width: 51, verticalAlign: 'middle', paddingRight: 1, borderBottom: 'none'  }}>
                        {row.picturePath !== "./images/default-profile.png" ? <img src={row.picturePath} style={{ width: 35, height: 35 }}  alt="profile" /> : <UserIcon style={{ width: 35, height: 35 }} />}
                        
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ width: "178px", height: 50, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                          <Typography variant="body" component="h2" color="black"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '21.78px',
                                WebkitLineClamp: 3,
                                maxHeight: 20,
                                textAlign: 'center',
                              }}>
                            {row.fullName}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{  width: "178px" , height: 50, overflow: 'hidden', textOverflow: 'ellipsis', }}>
                          <Typography variant="body" component="h1" color="black"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '16.94px',
                                WebkitLineClamp: 3,
                                maxHeight: 20,
                                textAlign: 'center'
                              }}>
                            {row.email}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{  width: "178px" , height: 50, overflow: 'hidden', textOverflow: 'ellipsis',}}>
                          <Typography variant="body" component="h1" color="black"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  lineHeight: '16.94px',
                                  WebkitLineClamp: 3,
                                  maxHeight: 20,
                                  textAlign: 'center'
                                }}>
                              {row.phoneNumber}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: "178px" , height: 50, overflow: 'hidden', }}>
                          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Typography variant="body" component="h1" color="black"
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '16.94px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center',
                                                            }}>
                                {row.highSchoolName}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell align="center" sx={{  width: "178px" , overflow: 'hidden', textOverflow: 'ellipsis', alignItems: 'center',}}>
                          <Typography variant="body" component="h1" color="black"
                                    sx={{
                                      fontFamily: 'Inter',
                                      fontSize: '14px',
                                      fontWeight: 400,
                                      lineHeight: '16.94px',
                                      WebkitLineClamp: 2,
                                      maxHeight: 20,
                                      textAlign: 'center',

                                    }}>
                                  {row.chapter.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: 10 }}>
                          <IconButton onClick={(event) => handleClickApproved(event, row)}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorElApproved}
                            open={Boolean(anchorElApproved)}
                            onClose={handleCloseApproved}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          >
                            <MenuItem onClick={handleEdit}>Edit</MenuItem>
                          </Menu>

                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </>
            )}

            </Box>
          </Grid>
        </Grid>
  </Grid>
</Box>
);
}
