import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Snackbar,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { Loader } from "@googlemaps/js-api-loader";
import Dropzone from 'react-dropzone';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  borderBottom: '1px solid #e0e0e0',
  padding: '16px 24px',
  fontWeight: 'bold',
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '1rem'
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '16px 24px',
  borderTop: '1px solid #e0e0e0',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: '6px 16px',
  borderRadius: '4px',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: '16px',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '16px',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: '10px 14px',
  },
}));

const SuggestionsList = styled(List)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1000,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  maxHeight: '200px',
  overflowY: 'auto',
  borderRadius: '4px',
  border: '1px solid #ccc',
}));

const SuggestionItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  padding: '8px 16px',
  textAlign: 'center',  // Center the text
}));

const AddHotelPopUp = ({ open, onClose, onHotelAdded, forceRerender }) => {
  const [formData, setFormData] = useState({
    chapterName: '',
    email: '',
    hotelName: '',
    hotelAddress: '',
    hotelZipCode: '',
    phone: '',
    name: '',
    picturePath: null,
    picturePathName: '',
    latitude: null,
    longitude: null,
  });
  const [chapters, setChapters] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      console.log("Google Maps API loaded successfully.");
    });
  }, []);

  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/chapter/getChapters`, { withCredentials: true });
        const data = response.data.result;
        setChapters(data.filter((d) => d.status === "activeChapter"));
      } catch (error) {
        console.error('Error fetching chapters:', error);
      }
    };

    fetchChapters();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleThumbnailChange = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFormData(prevData => ({
        ...prevData,
        picturePath: file,
        picturePathName: file.name
      }));
    }
  };

  const handleClose = () => {
    onClose();
    setFormData({
      chapterName: '',
      email: '',
      hotelName: '',
      hotelAddress: '',
      hotelZipCode: '',
      phone: '',
      name: '',
      picturePath: null,
      picturePathName: '',
      latitude: null,
      longitude: null,
    });
    setAddressSuggestions([]);
  }

  const handleSubmit = async () => {
    try {
      const zipcode = formData.hotelAddress.split(" ").pop();
      
      const formDataToSend = new FormData();
      
      formDataToSend.append('chapterName', formData.chapterName);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('hotelName', formData.hotelName);
      formDataToSend.append('hotelAddress', formData.hotelAddress);
      formDataToSend.append('hotelZipCode', zipcode);
      formDataToSend.append('phone', formData.phone);
      formDataToSend.append('name', formData.name);
      formDataToSend.append('hotelLatitude', formData.latitude);
      formDataToSend.append('hotelLongitude', formData.longitude);
      
      if (formData.picturePath) {
        formDataToSend.append('hotelImage', formData.picturePath);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/admin/hotels/add`, 
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        }
      );

      setSnackbarMessage('Hotel added successfully!');
      setSnackbarOpen(true);
      onHotelAdded(response.data.hotel);
      forceRerender();
      handleClose();
    } catch (error) {
      console.error('Error adding hotel:', error);
      setSnackbarMessage('Error adding hotel. Please try again.');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchAddressSuggestions = async (input) => {
    if (input.length > 3) {
      const service = new window.google.maps.places.AutocompleteService();
      const request = {
        input,
        componentRestrictions: { country: "us" },
        types: ["geocode"],
      };

      service.getPlacePredictions(request, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setAddressSuggestions(predictions.map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id,
          })));
        } else {
          setAddressSuggestions([]);
        }
      });
    } else {
      setAddressSuggestions([]);
    }
  };

  const fetchPlaceDetails = (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    const request = {
      placeId,
      fields: ['formatted_address', 'geometry'],
    };

    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          hotelAddress: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        }));
      }
    });
  };

  const handleAddressChange = (e) => {
    handleChange(e);
    fetchAddressSuggestions(e.target.value);
  };

  const handleAddressSelect = (description, placeId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hotelAddress: description,
    }));
    setAddressSuggestions([]);
    fetchPlaceDetails(placeId);
  };

  return (
    <>
      <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <StyledDialogTitle>Add New Hotel</StyledDialogTitle>
        <StyledDialogContent style={{ paddingTop: "1rem" }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <StyledFormControl fullWidth>
              <InputLabel id="chapter-label">Chapter</InputLabel>
              <StyledSelect
                labelId="chapter-label"
                id="chapterName"
                name="chapterName"
                value={formData.chapterName}
                onChange={handleChange}
                required
              >
                {chapters.map((chapter) => (
                  <MenuItem key={chapter.chapterId} value={chapter.chapterName}>
                    {chapter.chapterName}
                  </MenuItem>
                ))}
              </StyledSelect>
            </StyledFormControl>
            <StyledTextField
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              required
              fullWidth
            />
            <StyledTextField
              name="hotelName"
              label="Hotel name"
              value={formData.hotelName}
              onChange={handleChange}
              required
              fullWidth
            />
            <Box sx={{ position: 'relative' }}>
              <StyledTextField
                name="hotelAddress"
                label="Address"
                value={formData.hotelAddress}
                onChange={handleAddressChange}
                required
                fullWidth
              />
              {addressSuggestions.length > 0 && (
                <SuggestionsList>
                  {addressSuggestions.map((suggestion, index) => (
                    <SuggestionItem
                      key={index}
                      onClick={() => handleAddressSelect(suggestion.description, suggestion.placeId)}
                    >
                      <ListItemText 
                        primary={suggestion.description}
                        primaryTypographyProps={{
                          style: { 
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }
                        }}
                      />
                    </SuggestionItem>
                  ))}
                </SuggestionsList>
              )}
            </Box>
            
            <StyledTextField
              name="phone"
              label="Phone (optional)"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
            />
            <StyledTextField
              name="name"
              label="Name (optional)"
              value={formData.name}
              onChange={handleChange}
              fullWidth
            />
            <Box sx={{ marginBottom: '16px' }}>
              <Typography variant="body1" sx={{ marginBottom: '8px' }}>Hotel Image:</Typography>
              <Dropzone onDrop={handleThumbnailChange}>
                {({getRootProps, getInputProps}) => (
                  <Box
                    {...getRootProps({
                      className: `dropzone`,
                    })}  
                    sx={{
                      height: '150px', 
                      width: '100%', 
                      padding: '16px', 
                      border: '2px dashed #ccc',
                      borderRadius: '4px',
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'center', 
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <input {...getInputProps()} />
                    <Typography variant="body2" color="textSecondary" align="center">
                      Drag and Drop or <strong>Browse</strong>
                    </Typography>
                    {formData.picturePathName && (
                      <Typography variant="body2" color="primary" sx={{ marginTop: '8px' }}>
                        Selected file: {formData.picturePathName}
                      </Typography>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
          </Box>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton onClick={handleClose} variant="outlined">Cancel</StyledButton>
          <StyledButton onClick={handleSubmit} variant="contained" color="primary">
            Save
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default React.memo(AddHotelPopUp);