import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import DonationSection from "./DonationSection";
import NewDonationPopup from "./NewDonationPopup";
import WithdrawConfirmationPopup from "./WithdrawConfirmationPopup";

const DonationsPage = () => {
  const [donations, setDonations] = useState([]);
  const [showNewDonationPopup, setShowNewDonationPopup] = useState(false);
  const [showWithdrawConfirmation, setShowWithdrawConfirmation] = useState(false);
  const [currentDonation, setCurrentDonation] = useState(null);

  const handleNewDonation = (donation) => {
    setDonations([...donations, donation]);
    setShowNewDonationPopup(false);
  };

  const handleEditDonation = (updatedDonation) => {
    console.log(updatedDonation);
    setDonations(donations.map(donation => donation._id === updatedDonation._id ? {...donation, ...updatedDonation} : donation));
  };

  const handleConfirm = async (id) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/hotel/donation/confirm/${id}`, {status: "upcomingDelivery"});
      setDonations(donations.map(donation => donation._id === response.data.updatedDelivery._id ? response.data.updatedDelivery : donation));
      console.log(donations.map(donation => donation._id === response.data.updatedDelivery._id ? response.data.updatedDelivery : donation));

    } catch (err) {
      console.error(err);
    }
  };

  const handleWithdrawDonation = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/hotel/donation/${id}`, { withCredentials: true });
      setDonations(donations.filter(donation => donation._id !== id));
      setShowWithdrawConfirmation(false);
      console.log(response.data); // Check response
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchDonations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/hotel/donation`, {
          withCredentials: true
        });
        setDonations(response.data.deliveries);
        console.log(response.data.deliveries);
      } catch (err) {
        console.error(err);
      }
    };
    fetchDonations();
  }, []);

  const notConfirmedDonations = donations
  .filter((d) => d.status === "notConfirmedDelivery")
  .sort((a, b) => (a.placeConfirmation === 'Chapter' ? -1 : 1));
  
  const upcomingDonations = donations.filter(
    (d) => d.status === "upcomingDelivery"
  );
  
  const pastDonations = donations.filter(
    (d) => d.status === "pastDelivery"
  );

  return (
      <Box marginTop="28px" marginLeft="16px">
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <Button
            sx={{ borderRadius: "12px" }}
            variant="contained"
            color="primary"
            onClick={() => setShowNewDonationPopup(true)}
          >
            New Donation
          </Button>
        </Box>

        {donations.length === 0 ? (
          <Typography variant="h6" color="textSecondary">
            No donations available
          </Typography>
        ) : (
          <>
            {notConfirmedDonations.length > 0 &&
              <DonationSection
                title="Not confirmed donations"
                donations={notConfirmedDonations}
                onWithdraw={(donation) => {
                  setCurrentDonation(donation);
                  setShowWithdrawConfirmation(true);
                }}
                onEdit={handleEditDonation}
                onConfirm={handleConfirm}
              />
            }
            {upcomingDonations.length > 0 &&
              <DonationSection
                title="Upcoming donations"
                donations={upcomingDonations}
              />
            }
            {pastDonations.length > 0 &&
            <DonationSection
              title="Past donations"
              donations={pastDonations}
            />
            }
          </>
        )}

        {showNewDonationPopup && (
          <NewDonationPopup
            onClose={() => setShowNewDonationPopup(false)}
            onSave={handleNewDonation}
          />
        )}

        {showWithdrawConfirmation && (
          <WithdrawConfirmationPopup
            open={showWithdrawConfirmation}
            onClose={() => setShowWithdrawConfirmation(false)}
            onConfirm={() => handleWithdrawDonation(currentDonation._id)}
          />
        )}
      </Box>
  );
};

export default DonationsPage;
