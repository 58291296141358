import { Box, IconButton, Link, Typography } from "@mui/material";
import { FaTwitter, FaFacebook, FaGithubAlt, FaLinkedin } from "react-icons/fa";

const SocialIcon = ({ href, icon, color }) => (
  <IconButton
    size="large"
    component={Link}
    href={href}
    target="_blank"
    style={{ color: "#050A30" }}
  >
    {icon}
  </IconButton>
);

const links = [
  { href: "https://twitter.com/", icon: <FaTwitter /> },
  { href: "https://facebook.com/", icon: <FaFacebook /> },
  { href: "https://github.com", icon: <FaGithubAlt /> },
  { href: "https://linkedin.com", icon: <FaLinkedin /> },
];

const Footer = () => {
  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      p={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        {links.map((link) => (
          <SocialIcon
            key={link.href}
            href={link.href}
            icon={link.icon}
            color={link.color}
          />
        ))}
      </Box>
      <Typography color="#050A30" variant="body1" margin={1}>
        &copy; {new Date().getFullYear()} LinensNLove. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
