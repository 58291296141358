import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
  } from "@mui/material";
  import { useNavigate, useLocation } from "react-router-dom";
  
  import { ReactComponent as DonationIcon } from "../images/calendar.svg";
  import { ReactComponent as ChapterIcon } from "../images/school.svg";
  import { ReactComponent as ProfileIcon } from "../images/profile.svg";
  import { ReactComponent as MemberIcon } from "../images/member.svg";
  import { ReactComponent as ShelterIcon } from "../images/shelter.svg";
  import { ReactComponent as HotelIcon } from "../images/hotel.svg";
  
  const SideBar = ({role}) => {
    const navigate = useNavigate();
    const location = useLocation();
    let menuItems;
    const handleButtonClick = (route) => {
      navigate(route);
    };
  
    {role === 'Admin' ? menuItems = [
      { text: "Deliveries", icon: <DonationIcon />, route: "/delivery" },
      { text: "Chapters", icon: <ChapterIcon />, route: "/chapter" },
      { text: "Members", icon: <MemberIcon />, route: "/admin/members" },
      { text: "Hotels", icon: <HotelIcon />, route: "/hotels" },
      { text: "Shelters", icon: <ShelterIcon />, route: "/president/shelters" },
      { text: "Profile", icon: <ProfileIcon />, route: "/profile" },
    ] : role === 'chapterPresident' ? menuItems = [
		{ text: "Deliveries", icon: <DonationIcon />, route: "/delivery" },
		{ text: "My Chapter", icon: <ChapterIcon />, route: "/chapter" },
		{ text: "Members", icon: <MemberIcon />, route: "/president/members" },
		{ text: "Hotels", icon: <HotelIcon />, route: "/hotels" },
		{ text: "Shelters", icon: <ShelterIcon />, route: "/president/shelters" },
		{ text: "Profile", icon: <ProfileIcon />, route: "/profile" },
    ] : role === 'volunteerMember' ? menuItems = [
		{ text: "Deliveries", icon: <DonationIcon />, route: "/delivery" },
		{ text: "My Chapter", icon: <ChapterIcon />, route: "/chapter" },
		{ text: "Profile", icon: <ProfileIcon />, route: "/profile" },
    ] : menuItems = [
		{ text: "Donations", icon: <DonationIcon />, route: "/donations" },
		{ text: "My Chapter", icon: <ChapterIcon />, route: "/chapter" },
		{ text: "Profile", icon: <ProfileIcon />, route: "/profile" },
	 ]
    }
  
    return (
      <Box
        position="fixed"
        top="72px"
        left={0}
        height="100%"
        width="300px"
        bgcolor="lightgray"
        paddingTop={2}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            top: 100,
            backgroundColor: "white",
            display: "flex",
            alignItems: "top",
            marginBottom: 2,
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {menuItems.map((item) => (
              <ListItem key={item.text}>
                <ListItemButton
                  sx={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingTop: 1,
                    paddingBottom: 1,
                    color: location.pathname === item.route ? "#1814F3" : "#B1B1B1",
                    borderLeft: location.pathname === item.route ? "4px solid #1814F3" : "none",
                    "& .icon-box": {
                      "& svg": {
                        fill: location.pathname === item.route ? "#1814F3" : "#B1B1B1",
                      },
                    },
                    "& .MuiListItemText-root": {
                      color: location.pathname === item.route ? "#1814F3" : "#B1B1B1",
                    },
                    "&:hover": {
                      color: "#1814F3",
                      borderLeft: "4px solid #1814F3",
                      "& .icon-box": {
                        "& svg": {
                          fill: "#1814F3",
                        },
                      },
                      "& .MuiListItemText-root": {
                        color: "#1814F3",
                      },
                    },
                    "&:hover:not(.active)": {
                      color: "#1814F3",
                      borderLeft: "4px solid #1814F3",
                      "& .icon-box": {
                        "& svg": {
                          fill: "#1814F3",
                        },
                      },
                      "& .MuiListItemText-root": {
                        color: "#1814F3",
                      },
                    },
                    "&.active": {
                      color: "#1814F3",
                      borderLeft: "4px solid #1814F3",
                      "& .icon-box": {
                        "& svg": {
                          fill: "#1814F3",
                        },
                      },
                      "& .MuiListItemText-root": {
                        color: "#1814F3",
                      },
                    },
                  }}
                  onClick={() => handleButtonClick(item.route)}
                  className={location.pathname === item.route ? "active" : ""}
                >
                  <Box
                    className="icon-box"
                    sx={{
                      width: "1.5rem",
                      height: "1.5rem",
                      marginLeft: "0.5rem",
                      marginRight: "0.875rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "& svg": {
                        width: "100%",
                        height: "100%",
                        transition: "fill 0.3s",
                      },
                    }}
                  >
                    {item.icon}
                  </Box>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      fontFamily: "Inter",
                      fontSize: "1.5em",
                      fontWeight: 500,
                      lineHeight: "1.7em",
                      color: "inherit",
                      transition: "color 0.3s",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    );
  };
  
  export default SideBar;
  