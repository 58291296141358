import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const NavBar = ({role}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={2}
      position="fixed"
      width="100%"
      bgcolor="white"
      boxShadow={1}
      top={0}
      left={0}
      zIndex={1000}
    >
      <Link to="/">
        <img src="/logo.png" alt="linensnlove" width={75} />
      </Link>
      <Typography
        variant="h6"
        component="div"
        color="253f4a"
        fontSize={26}
        marginRight={2}
      >
        {role === 'hotelManager' && 'LinensNLove Hotel'}
        {role === 'Admin' && 'LinensNLove Admin'}
        {(role === 'volunteerMember' || role === 'chapterPresident') && 'LinensNLove Volunteer'}
      </Typography>         
    </Box>
  );
};

export default NavBar;