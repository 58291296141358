import Footer from "components/Footer";
import Login from "./Login";
import { Box } from "@mui/material";

const LoginPage = () => {
  return (
    <Box position="relative" top={-75} mx={4}>
      <Login />
      <Footer />
    </Box>
  );
};

export default LoginPage;
