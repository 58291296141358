import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography } from "@mui/material";

const Field = ({ label, placeHolder, name, value, onChange, rows = 1 , select = false, type='text', children}) => {
	return (
		<Box sx={{ display: "flex" }} gap={'5px'} width='100%' justifyContent={'flex-end'} alignItems={rows === 1 ? 'center' : 'flex-start'}>
			<Typography sx={{fontSize: '1.5rem', fontWeight: '400', minWidth:'fit-content'}}>{label}</Typography>
			<TextField
				placeholder={placeHolder}
				variant="outlined"
				fullWidth
				name={name}
				value={value}
				onChange={onChange}
				rows={rows}
				multiline={rows > 1}
				select= {select}
				type={type}
				sx={{
					height: '100%',
					width: '60%',
          padding: '2px',
          display:'flex',
          alignItems: 'center',
					'& .MuiInputBase-root': {
						borderRadius: 0,
						height: '100%'
					},
					'& .MuiInputBase-input': {
						borderRadius: 0,
						height: '100%'

					},
					
				}}
			>
        {children}
			</TextField>
		</Box>
	);
};


const EditDeliveryPopup = ({ open, onClose, delivery, onSave }) => {
  const [editedDelivery, setEditedDelivery] = useState({
    meetingAddress: "",
    deliveryAddress: "",
    deliveryDate: "",
    deliveryTime: "",
  });

  useEffect(() => {
    if (delivery) {
      // Initialize the edited delivery with the current delivery values
      setEditedDelivery({
        meetingAddress: delivery.meetingAddress,
        deliveryAddress: delivery.deliveryAddress,
        deliveryDate: delivery.deliveryDate,
        deliveryTime: delivery.deliveryTime,
      });
    }
  }, [delivery]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedDelivery((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onSave({ ...delivery, ...editedDelivery }); // Merge original delivery with edited values
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{
      sx: {
        borderRadius: '16px',
        minWidth: '660px',
      },
    }}>
      <DialogTitle>Edit Delivery</DialogTitle>
      <DialogContent>
      <Box display={'flex'} flexDirection={'column'} gap={'1.5rem'} sx={{height: '100%'}}>
        <Box display={'grid'} gap={'10px'}  sx={{justifyItems: 'flex-end'}}>
        <Field
          label="Date:"
          name= "deliveryDate"
          value={editedDelivery.deliveryDate.split("T")[0]}
          onChange={handleChange}
          type = 'date'
        />
        <Field
          label="Time:"
          name= "deliveryTime"
          value={editedDelivery.deliveryTime}
          onChange={handleChange}
          type = 'time'
        />          
        <Field
          label="Delivery Point:"
          name= "deliveryAddress"
          rows={4}
          value={editedDelivery.deliveryAddress}
          onChange={handleChange}
        />
        </Box>
      </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDeliveryPopup;
