import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";

const EditDonationPopup = ({ open, onClose, donation, onSave}) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [meetingPoint, setMeetingPoint] = useState("");
  const [location, setLocation] = useState("");

  // Update form fields when donation or open state changes
  useEffect(() => {
    if (open && donation) {
      setDate(donation.deliveryDate.split("T")[0] || "");
      setTime(donation.deliveryTime || "");
      setMeetingPoint(donation.meetingAddress || "");
      setLocation(donation.deliveryAddress || "");
    }
  }, [open, donation]);

  const handleSave = () => {
    const response = axios.put(`${process.env.REACT_APP_SERVER_URL}/hotel/donation/${donation._id}`, {
      id: donation._id,
      date,
      time,
      contents: "",
    },
    { withCredentials: true });
    const deliveryDate = date;
    const deliveryTime = time;
    console.log(donation);
    const placeConfirmation = 'Hotel';
    onSave({...donation, deliveryDate, deliveryTime, meetingPoint, location, placeConfirmation });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Donation</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Date"
          type="date"
          fullWidth
          variant="outlined"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          label="Time"
          type="time"
          fullWidth
          variant="outlined"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          label="Meeting Point"
          type="text"
          fullWidth
          variant="outlined"
          value={meetingPoint}
          onChange={(e) => setMeetingPoint(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Location"
          type="text"
          fullWidth
          variant="outlined"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDonationPopup;
