import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField, MenuItem } from '@mui/material';
import DeliveryPopUp from './DeliveryPopUp';
import axios from 'axios'
import { Menu } from '@mui/icons-material';

const Field = ({ label, placeHolder, name, value, onChange, rows = 1 , select = false, type='text', children}) => {
	return (
		<Box sx={{ display: "flex" }} gap={'5px'} width='100%' justifyContent={'flex-end'} alignItems={rows === 1 ? 'center' : 'flex-start'}>
			<Typography sx={{fontSize: '1.5rem', fontWeight: '400', minWidth:'fit-content'}}>{label}</Typography>
			<TextField
				placeholder={placeHolder}
				variant="outlined"
				fullWidth
				name={name}
				value={value}
				onChange={onChange}
				rows={rows}
				multiline={rows > 1}
				select= {select}
				type={type}
				sx={{
					height: '100%',
					width: '60%',
          padding: '2px',
          display:'flex',
          alignItems: 'center',
					'& .MuiInputBase-root': {
						borderRadius: 0,
						height: '100%'
					},
					'& .MuiInputBase-input': {
						borderRadius: 0,
						height: '100%'

					},

				}}
			>
        {children}
			</TextField>
		</Box>
	);
};

export default function DeliveryButton(props) {
  const deliveryId = props.id
  const[type, setType] = useState(props.type)
  const [formData, setFormData] = useState({
    deliveryDate: "",
		deliveryTime: "",
    deliveryPoint: "",
    hotel: "",
  });
  const[initialData, setInitialData] = useState({
    deliveryDate: "",
		deliveryTime: "",
    deliveryPoint: "",
    hotel: "",
  })
  const [error, setError] = useState("");
	const [openPopUp, setOpenPopUp] = useState(false);
	const [hotels, setHotels] = useState([]);

	useEffect(() => {
    const fetchData = async () => {
      try {
        if(type === 'new'){
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/chapterpresident/hotels`, { withCredentials: true });
          setHotels(response.data);
        }
        else if(type === 'edit'){

          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/delivery/getDelivery`,{"deliveryID": props.id});

          setInitialData({
            deliveryDate: response.data.deliveryDate,
            deliveryTime: response.data.deliveryTime,
            deliveryPoint: response.data.deliveryAddress
          });
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();

	}, []);

  useEffect(() =>{
    setFormData({...initialData})
  }, [initialData])

	const handleClose = () => {
		setOpenPopUp(false);
    if(type === 'new'){
      setFormData({
        deliveryDate: "",
        deliveryTime: "",
        deliveryPoint: "",
        hotel: "",
      });
    }
    else if(type === 'edit'){
      setFormData({...initialData});
    }
    setError("")
	}

	const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const { deliveryDate, hotel, deliveryPoint, deliveryTime} = formData;
    if (!deliveryDate || !deliveryPoint || !deliveryTime || (type == 'new' && !hotel)) {
      setError("Please fill out all fields.");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const hotel = hotels.find(hotel => hotel.hotelName === formData.hotel);
      const deliveryData = {
        date: formData.deliveryDate,
        time: formData.deliveryTime,
        deliveryPoint: formData.deliveryPoint,
        hotelID: hotel._id
      }
      const emailData = {
        hotelEmail: hotel.hotelManager.email,
        chapterName: hotel.chapter.name,
        hotelName: hotel.hotelName
      }

      const responseCreate = await axios.post(`${process.env.REACT_APP_SERVER_URL}/delivery/createDelivery`, deliveryData,{ withCredentials: true });
      props.onSave(responseCreate.data);
      setError("");
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
    handleClose();
  };

  const handleEdit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const deliveryData = {
        deliveryDate: formData.deliveryDate,
        deliveryTime: formData.deliveryTime,
        deliveryAddress: formData.deliveryPoint,
      }

      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/delivery/editDelivery/${deliveryId}`, deliveryData);
      setInitialData({
        deliveryDate: response.data['updatedDeliveryObj'].deliveryDate,
        deliveryTime: response.data['updatedDeliveryObj'].deliveryTime,
        deliveryPoint: response.data['updatedDeliveryObj'].deliveryAddress
      })
      setError("");
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
    handleClose();
  };


  return (
    <>
        <Button variant='contained' sx={{borderRadius: '8px'}} onClick={() => setOpenPopUp(true)}>
            New Delivery
        </Button>
        <DeliveryPopUp
					title = {type == 'new' ? "New Delivery" : "Edit Delivery"}
					openPopUp = {openPopUp}
					handleClose = {handleClose}
				>
					<Box display={'flex'} flexDirection={'column'} gap={'1.5rem'} sx={{height: '100%'}}>
						<Box display={'grid'} gap={'10px'}  sx={{justifyItems: 'flex-end'}}>
							<Field
								label="Date:"
								name= "deliveryDate"
								value={formData.deliveryDate}
								onChange={handleChange}
								type = 'date'
							/>
							<Field
								label="Time:"
								name= "deliveryTime"
								value={formData.deliveryTime}
								onChange={handleChange}
								type = 'time'
							/>
              {type === 'new' &&
                <Field
                  label="Hotel:"
                  name= "hotel"
                  select = {true}
                  value={formData.hotel}
                  onChange={handleChange}
                >
                  {hotels.map((hotel) => (
                    <MenuItem key={hotel._id} value={hotel.hotelName}>
                      {hotel.hotelName}
                    </MenuItem>
                  ))}
                </Field>
              }
							<Field
								label="Delivery Point:"
								name= "deliveryPoint"
								rows={4}
								value={formData.deliveryPoint}
								onChange={handleChange}
							/>
						</Box>
            <Typography color={'red'} display={'flex'} justifyContent={'center'} fontSize={'1rem'}>
              {error}
            </Typography>
						<Box display={'flex'} justifyContent={'center'} gap={'15%'}>
							<Button sx={{borderRadius: '8px' , minWidth:'fit-content', width: '20%'}} variant='outlined' onClick={type == 'new' ? handleSubmit : handleEdit}>
              {type == 'new' ? "Send" : "Save"}
							</Button>
							<Button sx={{borderRadius: '8px', minWidth:'fit-content', width: '20%'}} variant='outlined' onClick={handleClose}>
              {type == 'new' ? "Cancel" : "Discard "}
							</Button>
						</Box>
					</Box>

				</DeliveryPopUp>

    </>

  );
}
