import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';

import axios from 'axios'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import { useNavigate } from 'react-router-dom';

import logo from '../../images/logo.png';
import {ReactComponent as DeliveryIcon} from '../../images/calendar.svg';
import {ReactComponent as ChapterIcon} from '../../images/school.svg';
import {ReactComponent as HotelIcon} from '../../images/hotel.svg';
import {ReactComponent as UserIcon} from '../../images/user2.svg';
import {ReactComponent as ShelterIcon} from '../../images/house.svg';
import {ReactComponent as ProfileIcon} from '../../images/profile.svg';
import {ReactComponent as HotelIcon2} from '../../images/hotel.svg';

import AddHotelPopUp from './AddHotelPopUp';
import EditHotelPopUp from './EditHotelPopUp';
import HotelTable from './hotelTable';

export default function BasicTable() {
    const [anchorElIncoming, setAnchorElIncoming] = useState(null);
    const [selectedIncomingRow, setSelectedIncomingRow] = useState(null);
    const [anchorElPending, setAnchorElPending] =useState(null);
    const [selectedPendingRow, setSelectedPendingRow] = useState(null);
    const [anchorElApproved, setAnchorElApproved] = useState(null);
    const [selectedApprovedRow, setSelectedApprovedRow] = useState(null);

    // const [pendingHotels, setpendingHotels] = useState([]);
    const [pendingHotels, setPendingHotels] = useState([]);
    const [incomingHotels, setIncomingHotels] = useState([]);
    const [approvedHotels, setApprovedHotels] = useState([]);

    const [openAddHotelPopup, setOpenAddHotelPopup] = useState(false);
    const [openHotelPopup, setOpenHotelPopup] = useState(false);
    const [editingHotel, setEditingHotel] = useState([]);
    const [role, setRole] = useState("");

    const [rerenderKey, setRerenderKey] = useState(0);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/hotel/getHotels`, { withCredentials: true });
          const hotels = response.data.hotels;
          setRole(response.data.role);
          setPendingHotels(hotels.filter((h) => {
            return h.status === 'pendingInvitedHotel'}));
          

          setIncomingHotels(hotels.filter((h) => h.status === 'incomingRequestedHotel'));
          setApprovedHotels(hotels.filter((h) => h.status === 'activeHotel'));

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, []);

    const Navigate = useNavigate();

    const handleButtonClick = (route) => {
      Navigate(route);
    };

    const handleClickIncoming = (event, row) => {
      setAnchorElIncoming(event.currentTarget);
      setSelectedIncomingRow(row);
      setSelectedIncomingRow((updatedRow) => {
        return updatedRow;
      });
    };

    const handleCloseIncoming = () => {
      setAnchorElIncoming(null);
      setSelectedIncomingRow(null);
    };

    const handleClickPending = (event, row) => {
      setAnchorElPending(event.currentTarget);
      setSelectedPendingRow(row);
    };
    
    const handleClosePending = () => {
      setAnchorElPending(null);
      setSelectedPendingRow(null);
    };

    const handleApprove = async () => {
        try {
          await axios.patch(`${process.env.REACT_APP_SERVER_URL}/admin/hotels/${selectedIncomingRow.hotelId}/approve`);
          setIncomingHotels(incomingHotels.filter(hotel => hotel.hotelId !== selectedIncomingRow.hotelId));
          setApprovedHotels([...approvedHotels, { ...selectedIncomingRow, status: 'activeHotel' }]);
        } catch (error) {
          console.error('Error approving request:', error);
        }
        handleCloseIncoming();
      };

      const handleDecline = async () => {
        try {
          await axios.delete(`${process.env.REACT_APP_SERVER_URL}/admin/hotels/${selectedIncomingRow.hotelId}/reject`);
          setIncomingHotels(incomingHotels.filter(hotel => hotel.hotelId !== selectedIncomingRow.hotelId));
        } catch (error) {
          console.error('Error declining request:', error);
        }
        handleCloseIncoming();
      };


    const handleClickApproved = (event, row) => {
      setAnchorElApproved(event.currentTarget);
      setSelectedApprovedRow(row);
    };

    const handleCloseApproved = () => {
      setAnchorElApproved(null);
      setSelectedApprovedRow(null);
    };

    const handleEdit = (type) => {
      if(type === 'pending'){
        setEditingHotel(selectedPendingRow);
        handleClosePending();
      }
      else if(type === 'incoming'){
        setEditingHotel(selectedIncomingRow);
        handleCloseIncoming();
      }
      else{
        setEditingHotel(selectedApprovedRow);
        handleCloseApproved();
      }
      setOpenHotelPopup(true);

    };

    const handleRevoke = async() => {
      try {
        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/admin/hotels/${selectedPendingRow.hotelId}/revoke`);
        setPendingHotels(pendingHotels.filter(hotel => hotel.hotelId !== selectedPendingRow.hotelId));
      } catch (error) {
        console.error('Error revoke request:', error);
      }
      handleClosePending();
    };

    const handleResend = () => {
      handleCloseIncoming();
    };

    // handle Add Hotel button and window pop-up
    const handleAddHotel = () => {
      setEditingHotel(null);
      setOpenAddHotelPopup(true);
      handleCloseIncoming();
    };

    const handleCloseAddHotelPopup = () => {
      setOpenAddHotelPopup(false);
  };

  const handleHotelAdded = (newHotel) => {
    if(newHotel.status === 'pendingInvitedHotel'){
      setPendingHotels([...pendingHotels, newHotel]);
    }
    else if(newHotel.status === 'incomingRequestedHotel'){
      setIncomingHotels([...incomingHotels, newHotel]);
    }
    else{
      setApprovedHotels([...approvedHotels, newHotel]);
    }    
  };

  // handle editing existing incoming hotel info
  // const handleEditIncoming = () => {
  //   setEditingHotel(selectedIncomingRow);
  //   setOpenHotelPopup(true);
  //   handleCloseIncoming();
  //   console.log("editingHotel: ", editingHotel);
  // };

  const handleCloseHotelPopup = () => {
    setOpenHotelPopup(false);
    setEditingHotel(null);
  };

  const handleHotelEdited = (updatedHotel) => {
    if(updatedHotel.status === 'pendingInvitedHotel'){
      setPendingHotels(pendingHotels.map(hotel => 
        hotel.hotelId === updatedHotel.hotelId ? updatedHotel : hotel
      ));        
      handleClosePending();

    }
    else if(updatedHotel.status === 'incomingRequestedHotel'){
      setIncomingHotels(incomingHotels.map(hotel =>
        hotel.hotelId === updatedHotel.hotelId ? updatedHotel : hotel
      ));
      handleCloseIncoming();
    }
    else{
      setApprovedHotels(approvedHotels.map(hotel => 
        hotel.hotelId === updatedHotel.hotelId ? updatedHotel : hotel
      ));
      handleCloseApproved();
    }

  };

  const forceRerender = useCallback(() => {
    setRerenderKey(prevKey => prevKey + 1);
  }, []);



  const openIncoming = Boolean(anchorElIncoming);
  const openPending = Boolean(anchorElPending);
  const openApproved = Boolean(anchorElApproved);



  return (
    <Box>
      <Grid container>

        <Grid item xs={12} sx={{width:'100%'}}>
          <Box
            sx={{
              width: '100%',
              paddingTop: 2,
            }}
          >
            {role === 'chapterPresident' &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Button
                variant="contained"
                sx={{
                  width: '15%',
                  backgroundColor: '#1814F3',
                  fontSize: '15px',
                  color: 'white',
                  padding: '0.5%',
                  borderRadius: '15px',
                  textTransform: 'none', 
                }}
                onClick={handleAddHotel}
              >
                Add Hotel
              </Button>
              <AddHotelPopUp
                open={openAddHotelPopup}
                onClose={handleCloseAddHotelPopup}
                onHotelAdded={handleHotelAdded}
                forceRerender={forceRerender}
              />

            </Box>}

            {pendingHotels.length > 0 && 
              <HotelTable
                hotels = {pendingHotels}
                title = 'Pending invitations'
                handleClick = {handleClickPending}
                anchorEl={anchorElPending}
                open={openPending}
                handleClose={handleClosePending}        
                menuItems= {[
                  { label: 'Revoke Invite', onClick: handleRevoke },
                  { label: 'Resend', onClick: handleResend },
                  { label: 'Edit', onClick: () => handleEdit('pending') },
                ]}          
              />
            }

            {incomingHotels.length > 0 && 
              <HotelTable
                hotels = {incomingHotels}
                title = 'Incoming Request'
                handleClick = {handleClickIncoming}
                anchorEl = {anchorElIncoming}
                open = {openIncoming}
                
                handleClose = {handleCloseIncoming}            
                menuItems= {[
                  { label: 'Approve', onClick: handleApprove },
                  { label: 'Decline', onClick: handleDecline },
                  { label: 'Edit', onClick: () => handleEdit('incoming') },
                ]}          
              />
            }
            {approvedHotels.length > 0 && 
              <HotelTable
                hotels = {approvedHotels}
                title = 'Active hotels'
                handleClick = {handleClickApproved}
                anchorEl={anchorElApproved}
                open={openApproved}
                handleClose={handleCloseApproved}

                menuItems= {[
                  { label: 'Edit', onClick: () => handleEdit('approved') },
                ]}          
              />
            }

            {openHotelPopup && <EditHotelPopUp
                          open={openHotelPopup}
                          onClose={handleCloseHotelPopup}
                          onHotelSaved={handleHotelEdited}
                          hotel={editingHotel}
                        />}
          </Box>
        </Grid>
      </Grid>
    </Box>
);
}
