import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setLogin } from '../../state/index.js';

const AuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const role = params.get('role');
    const token = params.get('token');
    const user = params.get('user');
    const destination = params.get('destination');

    dispatch(setLogin({ role, token, user }));

    if (destination) {
      navigate(destination, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  }, [dispatch, navigate, location]);

  return <div>Authenticating...</div>;
};

export default AuthPage;

