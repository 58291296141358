import * as React from 'react';
import PopUp from 'components/PopUp';
import { Button, Dialog, DialogTitle, Box, DialogContent, Typography } from '@mui/material';

export default function WithdrawPopUp(props) {

    const{openPopUp, handleClose, handleWithdraw} = props;

    return (
            <PopUp
                title = "Withdraw Delivery"
                    openPopUp = {openPopUp}
                    handleClose = {handleClose}
                >
                    <Box height={'100px'} display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
                    <Typography display={'flex'} justifyContent={'center'} fontSize={'1.5rem'}>
                        Confirm that you are willing to withdraw the Chapter
                    </Typography>

                    <Box display={'flex'} justifyContent={'center'} gap={'15%'}>
                    <Button sx={{borderRadius: '8px' , minWidth:'fit-content', width: '20%'}} variant='outlined' onClick={handleWithdraw}>
                        Confirm
                    </Button>
                    <Button sx={{borderRadius: '8px', minWidth:'fit-content', width: '20%'}} variant='outlined' onClick={handleClose}>
                        Cancel
                    </Button>
                    </Box>

                </Box>
            </PopUp>

    );
}




