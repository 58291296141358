import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';

const NewDeliveryPopup = ({ onClose, onSave }) => {
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [contents, setContents] = useState('');

    const handleSave = async () => {
        const newDelivery = {
        date,
        time,
        contents,
        };
        try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/chapterpresident/delivery`, newDelivery, {
            withCredentials: true
        });
        onSave(response.data);
        } catch (err) {
        console.error(err);
        }
    };

    return (
        <Dialog open onClose={onClose}>
        <DialogTitle>New Delivery</DialogTitle>
        <DialogContent>
            <TextField
            margin="dense"
            label="Date"
            type="date"
            fullWidth
            value={date}
            onChange={(e) => setDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            />
            <TextField
            margin="dense"
            label="Time"
            type="time"
            fullWidth
            value={time}
            onChange={(e) => setTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            />
            <TextField
            margin="dense"
            label="Contents"
            fullWidth
            multiline
            rows={4}
            value={contents}
            onChange={(e) => setContents(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">Cancel</Button>
            <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
        </Dialog>
    );
    };

export default NewDeliveryPopup
