import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField, MenuItem } from '@mui/material';
import Dropzone from 'react-dropzone';
import PopUp from '../../components/PopUp';
import axios from 'axios'
import { Menu } from '@mui/icons-material';
import { Loader } from "@googlemaps/js-api-loader";

const Field = ({ label, placeHolder, name, value, onChange, rows = 1 , select = false, type='text', children}) => {
	return (
		<Box sx={{ display: "flex" }} gap={'5px'} width='100%' justifyContent={'flex-end'} alignItems={rows === 1 ? 'center' : 'flex-start'}>
			<Typography sx={{fontSize: '1.5rem', fontWeight: '400', minWidth:'fit-content'}}>{label}</Typography>
			<TextField
				placeholder={placeHolder}
				variant="outlined"
				fullWidth
				name={name}
				value={value}
				onChange={onChange}
				rows={rows}
				multiline={rows > 1}
				select= {select}
				type={type}
				sx={{
					height: '100%',
					width: '60%',
          padding: '2px',
          display:'flex',
          alignItems: 'center',
					'& .MuiInputBase-root': {
						borderRadius: 0,
						height: '100%'
					},
					'& .MuiInputBase-input': {
						borderRadius: 0,
						height: '100%'

					},

				}}
			>
        {children}
			</TextField>
		</Box>
	);
};

export default function ChapterButton(props) {
  const {type, chapter, onSubmit, onEdit} = props;
  const [formData, setFormData] = useState({
    name: "",
	  address: "",
    chapterPhoto: null,
    chapterPhotoName: "",
    latitude: "",
    longitude: ""

  });
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  const[initialData, setInitialData] = useState({
    name: "",
    address: "",
    chapterPhoto: null,
    chapterPhotoName: "",
    latitude: "",
    longitude: ""
  })

  const [error, setError] = useState("");
	const [openPopUp, setOpenPopUp] = useState(false);

	useEffect(() => {
    if (chapter) {
      setInitialData({
        name: chapter.chapterName || "",
        address: chapter.chapterAddress || "",
        chapterPhoto: chapter.chapterPhoto || ""
      });

    }

	}, [chapter]);

  useEffect(() =>{
    setFormData({...initialData})
  }, [initialData])

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      console.log("Google Maps API loaded successfully.");
    });
  }, []);

  const handleClose = () => {
    setOpenPopUp(false);
    if(type === 'new'){
      setFormData({
        name: "",
        address: "",
        chapterPhoto: null,
        chapterPhotoName: "",
        latitude: "",
        longitude: ""
      });
    }
    else if(type === 'edit'){
      setFormData({...initialData});
    }
    setAddressSuggestions([]);
    setError("")
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddressChange = (e) => {
    handleChange(e);
    fetchAddressSuggestions(e.target.value);
  };

  const validateForm = () => {
    const { name, address} = formData;
    if (!name || !address) {
      setError("Please fill out all fields.");
      return false;
    }
    return true;
  };

  const handleFileChange = (acceptedFiles) => {
    console.log(acceptedFiles);
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFormData(prevData => ({
        ...prevData,
        chapterPhoto: file,
        chapterPhotoName: file.name
      }));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const reqForm = new FormData();
      reqForm.append('chapterName', formData.name);
      reqForm.append('chapterAddress', formData.address);
      if (formData.chapterPhoto) {
        reqForm.append('chapterPhoto', formData.chapterPhoto);
      }
      reqForm.append('latitude', formData.latitude);
      reqForm.append('longitude', formData.longitude);
      console.log(reqForm);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      };
      console.log("FormData contents:");
      for (let pair of reqForm.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }
  
      const responseCreate = await axios.post(`${process.env.REACT_APP_SERVER_URL}/chapter/createChapter`, reqForm, config);
      const newChapter = responseCreate.data.result;
      onSubmit(newChapter);
      setError("");
    } catch (err) {
      console.error("Error submitting form:", err);
      setError("An error occurred. Please try again.");
    }
    handleClose();
  };


  const handleEdit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const chapterData = {
        chapterName: formData.name,
        chapterAddress: formData.address,
        chapterPhoto: formData.chapterPhoto,
        chapterPhotoName: formData.chapterPhotoName,
        latitude: formData.latitude,
        longitude: formData.longitude,
        userStatus: chapter.userStatus

      }
      const chapterId = chapter.chapterId
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/chapter/editChapter/${chapterId}`, chapterData);
      console.log(response.data);

      setInitialData({
        name: response.data.result.chapterName,
        address: response.data.result.chapterAddress,
        chapterPhoto: response.data.result.chapterPhoto,
        latitude: response.data.result.latitude,
        longitude: response.data.result.longitude,
      })
      onEdit(response.data.result);
      setError("");
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
    handleClose();
  };

  const fetchAddressSuggestions = async (input) => {
    if (input.length > 3) {
      const service = new window.google.maps.places.AutocompleteService();
      const request = {
        input,
        componentRestrictions: { country: "us" },
        types: ["geocode"],
      };

      service.getPlacePredictions(request, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setAddressSuggestions(predictions.map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id,
          })));
        } else {
          setAddressSuggestions([]);
        }
      });
    } else {
      setAddressSuggestions([]);
    }
  };
  const handleAddressSelect = (description, placeId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: description,
    }));
    setAddressSuggestions([]);
    fetchPlaceDetails(placeId);
  };

  const fetchPlaceDetails = (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    const request = {
      placeId,
      fields: ['formatted_address', 'geometry'],
    };

    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          address: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        }));
      }
    });
  };

  return (
    <>
        {type === 'new'?
          <Button variant='outlined' sx={{borderRadius: '8px', padding:'0.5rem 1.5rem'}} onClick={() => setOpenPopUp(true)}>
            Request New
          </Button>
        :
          <MenuItem onClick={() => {
            setOpenPopUp(true);
          }}>Edit</MenuItem>
        }

        <PopUp
					title = {type === 'new' ? "New Chapter" : "Edit Chapter"}
					openPopUp = {openPopUp}
					handleClose = {handleClose}
				>
					<Box display={'flex'} flexDirection={'column'} gap={'1.5rem'} sx={{height: '100%'}}>
						<Box display={'grid'} gap={'10px'}  sx={{justifyItems: 'flex-end'}}>
							<Field
								label="Name:"
								name= "name"
								value={formData.name}
								onChange={handleChange}
							/>
              <Box width='100%' display='inline-block'>
                <Field
                  label="Address:"
                  name= "address"
                  value={formData.address}
                  onChange={handleAddressChange}
                />
                {addressSuggestions.length > 0 && (
                <Box sx={{ position: "absolute", left:'calc(40% + 9px)', zIndex: 1000, background: "white", border: "1px solid #ccc" }}>
                  {addressSuggestions.map((suggestion, index) => (
                    <Box
                      key={index}
                      sx={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleAddressSelect(suggestion.description, suggestion.placeId)}
                    >
                      {suggestion.description}
                    </Box>
                  ))}
                </Box>
                )}
              </Box>

              <Box display='flex' gap={'5px'} width='100%' justifyContent={'flex-end'} alignItems='flex-start'>
              <Typography sx={{fontSize: '1.5rem', fontWeight: '400', minWidth:'fit-content'}}>Thumbnail:</Typography>

              <Dropzone onDrop={handleFileChange}>
                {({getRootProps, getInputProps}) => (
                  <Box
                    {...getRootProps({
                      className: `dropzone`,
                    })}  
                    sx={{
                      height: '150px', 
                      width: '60%', 
                      padding: '2px', 
                      border:'1px dashed gray', 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems:'center', 
                      justifyContent: 'center'
                    }}
                  >
                    <Box sx={{width:'50%'}}>
                      <Typography sx={{fontSize: '1.5rem', fontWeight: '400', minWidth:'fit-content', color:'gray', textAlign:'center'}}>
                        Drag and Drop or <strong>Browse</strong>
                      </Typography>
                    </Box>
                    {formData.chapterPhotoName && (
                      <Typography sx={{fontSize: '1rem', color: 'green', marginTop: '10px'}}>
                        Selected file: {formData.chapterPhotoName}
                      </Typography>
                    )}
                    <input {...getInputProps()} />
                  </Box>
                )}
              </Dropzone>
            </Box>

						</Box>
            <Typography color={'red'} display={'flex'} justifyContent={'center'} fontSize={'1rem'}>
              {error}
            </Typography>
						<Box display={'flex'} justifyContent={'center'} gap={'15%'}>
							<Button sx={{borderRadius: '8px' , minWidth:'fit-content', width: '20%'}} variant='outlined' onClick={type === 'new' ? handleSubmit : handleEdit}>
                {type === 'new' ? "Send" : "Save"}
							</Button>
							<Button sx={{borderRadius: '8px', minWidth:'fit-content', width: '20%'}} variant='outlined' onClick={handleClose}>
                Cancel
							</Button>
						</Box>
					</Box>

				</PopUp>

    </>

  );
}
