import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import LoginPage from "scenes/loginPage";
import HomePage from "scenes/homePage";
import RegisterPage from "scenes/registerPage";
import ProfilePage from "scenes/profilePage";
import Layout from "components/Layout";
import AdminPage from "scenes/adminPage";
import HotelPage from "scenes/hotelsPage";
import { themeSettings } from "./theme";
import NavBar from "components/NavBar";
import StatusPage from "scenes/statusPage";
import DeliveriesPage from "scenes/deliveryPage";
import DonationPage from "scenes/donationPage";
import ChapterPage from "scenes/chapterPage";
import PresidentPage from "scenes/PresidentPage";
import {useMediaQuery } from "@mui/material";
import { GoogleOAuthProvider } from '@react-oauth/google';
import VolunteerDashboard from "scenes/MemberPage"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLogin, setLogout } from './state/index.js';
import AuthHandler from "scenes/authPage/Auth.jsx";
import axios from "axios";

const ProtectedRoute = ({ element, allowedRoles }) => {
  const isAuth = useSelector((state) => {
    return Boolean(state.token)});
  const userRole = useSelector((state) => state.role);

  if (!isAuth) {
    console.log("auth not ava");
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    console.log("not allowed", element);

    return <Navigate to="/login" />;
  }

  return element;
};

function App() {
  const isAuth = useSelector((state) => {
    return Boolean(state.token);
  });
  const userRole = useSelector((state) => {
    return state.role});
  const mode = useSelector((state) => state.mode);
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const isCustomBreakpoint = useMediaQuery('(max-width:1400px)');

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);


    const handleMenuToggle = () => {
      setMenuOpen(!menuOpen);
    };
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_CLIENT_ID}
      debug={true}
    >

    <div className="app">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/status" element={<StatusPage />} />
            <Route path="/profile" element={!isAuth ? <Navigate to="/" /> :<Layout><ProfilePage /></Layout>} />
            <Route path="/auth" element={<AuthHandler/>} />

            <Route
              path="/admin/members"
              element={
                <ProtectedRoute
                  element={<Layout><AdminPage /></Layout>}
                  allowedRoles={["Admin"]}
                />
              }
            />
            {/* Chapter President Routes */}
            <Route
              path="/hotels"
              element={
                <ProtectedRoute
                  element={<Layout><PresidentPage /></Layout>}
                  allowedRoles={["chapterPresident", "Admin"]}
                />
              }
            />
            <Route
              path="/president/members"
              element={
                <ProtectedRoute
                  element={<Layout><VolunteerDashboard /></Layout>}
                  allowedRoles={["chapterPresident"]}
                />
              }
            />

            {/* Shared Routes */}
            <Route
              path="/delivery"
              element={
                <ProtectedRoute
                  element={<Layout><DeliveriesPage /></Layout>}
                  allowedRoles={["Admin", "chapterPresident", "volunteerMember"]}
                />
              }
            />
            <Route
              path="/donations"
              element={
                <ProtectedRoute
                  element={<Layout><DonationPage /></Layout>}
                  allowedRoles={["hotelManager"]}
                />
              }
            />
            <Route
              path="/chapter"
              element={
                <ProtectedRoute
                  element={<Layout><ChapterPage menuOpen={menuOpen} isCustomBreakpoint={isCustomBreakpoint}/></Layout>}
                  allowedRoles={["Admin", "chapterPresident", "volunteerMember", "hotelManager"]}
                />
              }
            />
              {/* <Route path="/test" element={isAuth ? <Navigate to="/" /> : <HomePage />} /> */}
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </GoogleOAuthProvider>
  );
}

// Helper component to conditionally render the NavBar based on the current path
function ConditionalNavBar() {
  const location = useLocation();
  const showNavBarPaths = ["/login", "/register"];

  return showNavBarPaths.includes(location.pathname) ? <NavBar /> : null;
}

export default App;
