import { Box, Button, Typography } from "@mui/material";

const StatusScreen = ({status}) => {
  const message = {
    rejected: "Your request has been rejected",
    requestedMember:
      "The president of the LinensNLove chapter closest to your location is currently reviewing your request to join. If you believe too much time has elapsed, you can resubmit your request by clicking the Re-send request button. This will send a reminder to the chapter president to check your request.",
    invitedMember: "Your request has been submitted recently.",
  };

  return (
    <Box>
      <Typography>Your request status</Typography>
      <Box>
        <Typography>{message[status]}</Typography>
        {status === "requestedMember" && <Button>Re-send request</Button>}
      </Box>
    </Box>
  );
};

export default StatusScreen;
