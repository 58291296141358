import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditDeliveryPopup from "./EditDeliveryPopup"
import ImageIcon from '@mui/icons-material/Image';import PhotoPopup from "./PhotoPopup";
;


const DeliveryCard = ({ delivery, onWithdraw, onEdit, onConfirm, onSignUp, onParticipateList, role, onManagePhotos, onDiscard}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [showEditDeliveryPopup, setShowEditDeliveryPopup] = useState(false);
  // console.log(onManagePhoto)
  const [showPhotoPopup, setShowPhotoPopup] = useState(false);
  
  function convertTo12Hour(time24) {
    const [hours, minutes] = time24.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  }

  const openMenu = Boolean(anchorEl);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // const isPast = new Date(delivery.deliveryDate) < new Date();
  const bgColor = delivery.status === 'pastDelivery' ? "#ededed" : "white";
  const textColor = delivery.status === 'pastDelivery' ? "gray" : "black";
  const borderColor = (delivery.placeConfirmation === "Hotel" && delivery.status === "notConfirmedDelivery" && role !== 'Admin') ? "red" : "transparent";
  const numberOfImages = 10;
  //DATE IS BEING PRINTED ONE DAY BEHIND, SO THE FOLLOWING TWO LINES FIXES THAT.
  const deliveryDate = new Date(delivery.deliveryDate);
  const formattedDate = `${deliveryDate.getMonth() + 1}/${deliveryDate.getDate() + 1}/${deliveryDate.getFullYear()}`;

  const handleEdit = () => {
    console.log(delivery);
    handleMenuClose();
    setShowEditDeliveryPopup(true);
  };

  const handleWithdraw = () => {
    handleMenuClose();
    onWithdraw(delivery);
  };

  const handleConfirm = () => {
    handleMenuClose();
    onConfirm(delivery._id);
  };

  const handleSaveEdit = (updatedDelivery) => {
    onEdit(updatedDelivery);
    setShowEditDeliveryPopup(false);
  };

  const handleSignUp = () => {
    handleMenuClose();
    onSignUp(delivery._id);
  }


  const handleDiscard = () => {
    handleMenuClose();
    onDiscard(delivery._id);
  }

  const handleParticipateList = () => {
    handleMenuClose();
    onParticipateList(delivery);
  }

  const handleManagePhotos = (links) => {
    handleMenuClose();
    onManagePhotos(delivery, links);
  }
  const handleOpenPhoto = () => {
    setShowPhotoPopup(true);
    handleMenuClose();

  }

  return (
    <>
      <Card
        sx={{
          backgroundColor: bgColor,
          padding: 3,
          paddingLeft: 5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
          borderRadius: "16px",
          boxShadow: 0,
          border: `2px solid ${borderColor}`,
          position: 'relative'
        }}
      >
        <Box display="flex" alignItems="flex-start" gap={6} flexGrow={1}>
          <Box>
            <Typography
              variant="body1"
              fontWeight="semibold"
              fontSize={24}
              color={textColor}
            >
              {formattedDate}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="semibold"
              fontSize={24}
              color={textColor}
            >
              {convertTo12Hour(delivery.deliveryTime)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" maxWidth="50%">
            <Typography
              variant="body1"
              fontWeight="bold"
              fontSize={14}
              color={textColor}
            >
              Meeting Point: {delivery.meetingAddress}
            </Typography>
            <Typography
              variant="body2"
              fontSize={14}
              color={textColor}
            >
              To: {delivery.deliveryAddress}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" gap={2} >
          {delivery.status !== "notConfirmedDelivery" && (
            <Box
              display="flex"
              flexWrap="wrap"
              gap={1}
              width={120}
              alignContent="flex-start"
            >
              {[...Array(numberOfImages)].map((_, index) => (
                <ImageIcon key={index} sx={{ color: textColor, fontSize: 20 }} />
              ))}
            </Box>
          )}
          <Box display="flex" flexDirection='column' alignItems="center" justifyContent='space-between'>
            <Box>
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
            </Box>
            <Box>
              {delivery.isJoined && <CheckIcon sx={{ color: 'blue', fontSize: 30 }} />}
            </Box>
          </Box>


        </Box>

        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          disableAutoFocusItem={true}
          disableScrollLock={true} 
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: {
              width: 150,
              position: 'absolute',
              top: anchorEl ? anchorEl.getBoundingClientRect().top - anchorEl.offsetHeight : 0, // Adjust based on Card position
              left: anchorEl ? anchorEl.getBoundingClientRect().left : 0, // Adjust based on Card position
            },
          }}
        >
        {
          (role === "chapterPresident" || role === 'Admin') ? ( //PRESIDENT MENU BUTTONS
            delivery.status === "upcomingDelivery" ? (
              [
                <MenuItem key="edit" onClick={handleEdit}>Edit</MenuItem>,
                !delivery.isJoined ? <MenuItem key="signup" onClick={handleSignUp}>Sign up</MenuItem> : <MenuItem key="discard" onClick={handleDiscard}>Discard</MenuItem>,
                <MenuItem key="participatelist" onClick={handleParticipateList}>Participate List</MenuItem>
              ]
            ) : (
              delivery.placeConfirmation === "Hotel" && delivery.status !== "pastDelivery"  ? (
                [
                  <MenuItem key="edit" onClick={handleEdit}>Edit</MenuItem>,
                  <MenuItem key="withdraw" onClick={handleWithdraw}>Withdraw</MenuItem>,
                  delivery.status === "notConfirmedDelivery" && (
                    <MenuItem key="confirm" onClick={handleConfirm}>Confirm</MenuItem>
                  )
                ].filter(Boolean) // Remove false values from the array
              ) :  delivery.status === "pastDelivery" ? (
                <MenuItem key="managePhotos" onClick={handleOpenPhoto}>Manage Photos</MenuItem>
                ) :
              (
                [
                  <MenuItem key="edit" onClick={handleEdit}>Edit</MenuItem>,
                  <MenuItem key="withdraw" onClick={handleWithdraw}>Withdraw</MenuItem>
                ]
              )
            )
          ) : ( //VOLUNTEER MENU BUTTONS ***FIX****
            delivery.status === "upcomingDelivery" && !delivery.isJoined ? (
              <MenuItem key="signup" onClick={handleSignUp}>Sign Up</MenuItem>
            ) : delivery.status === "upcomingDelivery" && delivery.isJoined ? (
              <MenuItem key="discard" onClick={handleDiscard}>Discard</MenuItem>
            )
            : (
              <MenuItem key="seePhotos" onClick={handleOpenPhoto}>See photos</MenuItem>
            )
          )
        }

        </Menu>
      </Card>
      {showEditDeliveryPopup && (
        <EditDeliveryPopup
          open={showEditDeliveryPopup}
          onClose={() => setShowEditDeliveryPopup(false)}
          delivery={delivery}
          onSave={handleSaveEdit}
        />
      )}
      {showPhotoPopup && (
        <PhotoPopup 
        open={showPhotoPopup}
        onClose={() => setShowPhotoPopup(false)}
        delivery={delivery}
        onSave={handleManagePhotos}
        role={role}
        />

      )
      
      }
    </>
  );
};

export default DeliveryCard;
