import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const PhotoPopup = ({ open, onClose, delivery, onSave, role }) => {
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState("");

  useEffect(() => {
    if (delivery && delivery.googleDriveLink) {
      setLinks(delivery.googleDriveLink);
    }
    console.log(delivery);
  }, [delivery]);

  const handleAddLink = () => {
    if (newLink) {
      setLinks([...links, newLink]);
      setNewLink("");
    }
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
  };

  const handleSave = () => {
    onSave(links);
    setNewLink("");

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Links to photo and video</DialogTitle>
      <DialogContent>
        <List>
        {links.length === 0 ? (
          <ListItem>
            <ListItemText primary="No photos uploaded" />
          </ListItem>
        ) : (
          links.map((link, index) => (
            <ListItem 
              key={index} 
              secondaryAction={
                (role === 'chapterPresident' || role === 'Admin') && (
                  <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveLink(index)}>
                    <CloseIcon />
                  </IconButton>
                )
              }
            >
              <ListItemText
                primary={
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                  </a>
                }
              />
            </ListItem>
          ))
        )}
        </List>
        { (role === 'chapterPresident' || role === 'Admin') &&
        <TextField
          label="Add new link"
          value={newLink}
          onChange={(e) => setNewLink(e.target.value)}
          fullWidth
          margin="normal"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleAddLink();
            }
          }}
        />
        }
      </DialogContent>
      
      <DialogActions>
        {(role === 'chapterPresident' || role === 'Admin') ? (
          <>
            <Button onClick={onClose}>Discard</Button>
            <Button onClick={handleSave}>Save</Button>
          </>
        ) : 
        (
          <Button onClick={onClose}>Close</Button>
        )
        }
      </DialogActions>
    </Dialog>
  );
};

export default PhotoPopup;
